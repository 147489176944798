<!--
<script setup>
import store from "@/store";
import ModalAlert from "@/components/common/ModalAlert.vue";
import { computed } from "vue";

const modalAlert = computed(() => store.state.modalAlert);
</script>

<template>
  <Teleport to="#app">
    <ModalAlert
      :show="modalAlert.showModal"
      @close="store.commit('closeModalAlert')"
    >
      <template #body>
        <div v-html="modalAlert.message"></div>
      </template>
    </ModalAlert>
  </Teleport>

  <headerPage />
  <router-view />
</template>
 -->

<!-- <script setup>
import { useMeta } from "vue-meta";

useMeta({
  title: "",
  meta: [
    {
      property: "description",
      name: "description",
      content:
        "우리회사 건강관리 해답은 KB오케어 - 기업 임직원 건강검진 서비스 / 기업 특화 건강관리 복지 프로그램 / 임직원 마음건강 (멘탈케어) / 개인별 맞춤 건강관리 / 다양한 리워드 제공",
    },
    { property: "og:title", name: "og:title", content: "KB 헬스케어" },
  ],
  htmlAttrs: { lang: "ko", amp: true },
});
</script> -->

<template>
  <metainfo />
  <router-view />
</template>
