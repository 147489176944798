import * as common from "@/api/common";

/**
 * 공통코드 조회
 * @param {} param0
 * @param {*} param1
 */
export async function getListCodeByGrpId({ commit }, { grpId }) {
  const data = await common._getListCodeByGrpId(grpId);

  commit("setListCodeByGrpId", data);
}
