import LayoutOcare from "@/layout/LayoutOcare.vue";
import OcarePage from "@/views/ocare/OcarePage.vue"; // 추가

const ocare = {
  path: "ocare",
  component: LayoutOcare,
  meta: {
    title: "국민과 건강사이 KB헬스케어", 
  },
  children: [
    {
      path: "",
      name: "OcarePage",
      component: OcarePage,
    },
  ],
};

export default ocare;
