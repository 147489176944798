<template>
	<div class="terms">
		<h2>오케어 서비스 이용 약관</h2>
		<h3>목차</h3>
		<ol>
			<li>1. 여러분의 건강을 기원합니다</li>
			<li>2. 오케어 서비스는 다채롭습니다</li>
			<li>3. 회사는 여러분의 건강관리에 최선을 다합니다만, 의료행위를 제공하지는 않습니다</li>
			<li>4. 회원이 되시면 오케어 서비스를 더욱 다양하게 사용할 수 있습니다</li>
			<li>5. 오케어 서비스 이용을 위한 주의사항이 있습니다</li>
			<li>6. 회사는 여러분의 개인정보 보호를 위해 최선을 다하겠습니다.</li>
			<li>7. 회사에서 제공하는 다양한 포인트를 활용해 보세요</li>
			<li>8. 오케어 서비스를 중단하거나 변경할 경우 꼭 알려드리겠습니다.</li>
			<li>9. 회사는 이용약관 및 운영정책을 사전 공지 후 개정합니다.</li>
		</ol>
		<h4>여러분의 건강을 기원합니다</h4>
		<ul>
			<li>오케어 서비스를 이용해 주셔서 감사합니다.</li>
			<li>본 이용약관은 다양한 오케어 서비스의 이용과 관련하여 오케어 서비스를 제공하는 주식회사 케이비헬스케어(이하 회사)와 오케어 서비스를 이용하는 회원 또는 비회원과의 관계를 설명합니다.
				<ul>
					<li>오케어 서비스란 오케어 홈페이지 및 오케어 앱과 관련된 제반 서비스를 의미합니다.</li>
					<li>파트너 서비스란 오케어와 제휴된 헬스 관련 서비스 회사들이 제공하는 유무상 서비스를 의미합니다.</li>
				</ul>
			</li>
			<li>오케어 서비스를 이용하시거나 오케어 서비스 회원으로 가입하실 경우 여러분은 본 약관 및 운영 정책을 확인하거나 동의하게 되므로, 조금만 시간을 내서 주의 깊게 읽어 주시기 바랍니다.</li>
		</ul>
		<h4>오케어 서비스는 다채롭습니다</h4>
		<ul>
			<li>회사는 여러분이 신체적, 정신적, 사회적 안녕을 즐길 수 있도록 필요한 모든 건강 서비스를 제공하기 위해 최선을 다하겠습니다.</li>
			<li>회사에서는 오케어 자체 서비스뿐만 아니라 다양한 서비스 파트너가 여러분들에게 건강을 조금 더 쉽게 관리할 수 있도록 함께 노력하고 있습니다.</li>
			<li>회사는 여러분의 건강을 위해 엄격한 기준으로 건강 관련 서비스 파트너를 선정하고, 여러분께 소개해드리지만 각 서비스에서 자체적으로 제공하는 콘텐츠 내용, 정보의 정확성 등에 관한 모든 책임은 오케어가 아닌 서비스 파트너들에게 있습니다.</li>
			<li>오케어 서비스에는 기본적으로 본 약관이 적용됩니다만 회사가 다양한 서비스를 제공하는 과정에서 부득이 본 약관 외 별도의 약관, 운영정책 등을 적용하는 경우가 있습니다. 본 약관과 별도의 약관, 운영정책이 충돌할 경우, 별도의 약관 및 별도의 운영정책을 우선 적용합니다.</li>
			<li>회사와 서비스 파트너가 제공하는 유료서비스의 경우에도 여러분이 별도의 유료이용약관에 동의한 때에 파트너와 여러분 간의 유료서비스 이용계약이 성립합니다.</li>
			<li>이러한 내용은 각각의 해당 서비스 초기 화면에서 확인해 주시기 바랍니다.</li>
		</ul>
		<h4>회사는 여러분의 건강관리에 최선을 다하겠습니다. 하지만 의료행위를 제공하지는 않습니다</h4>
		<ul>
			<li>오케어 서비스는 개인의 질병을 진단, 치료하는 등 의료 행위를 의미하지는 않습니다. 혹시라도 오케어를 사용하시는 도중, 여러분의 신체나 건강에 이상이 발생할 경우, 반드시 의료기관을 방문하여 의료진에게 상담을 받으시기 바랍니다.</li>
			<li>여러분은 본인의 건강 상태 및 주변의 운동 환경 등을 고려하여 필요에 따라 의료기관 및 의료진의 자문을 받아야 합니다. 여러분이 오케어 서비스 및 파트너 서비스 내 프로그램 수행에 따라 발생한 건강 이상이나 손해에 대해 책임을 지지 않습니다.</li>
			<li>오케어 및 파트너 서비스를 이용 중에 통증, 현기증, 탈진, 호흡곤란, 무기력증, 통증 등 건강 이상을 느끼시면 즉시 서비스를 중단하셔야 합니다.</li>
			<li>오케어와 연동되는 액세서리 기기들은 항상 이용약관이나 관련 법령이 허용되는 범위 내에서만 사용되어야 하며, 이를 벗어난 사용 중에 발생하는 문제에 대해서는 회사는 어떠한 사항도 책임지지 않습니다.</li>
			<li>오케어 서비스에서 제공하는 건강 정보는 일반적인 정보이므로, 회사는 이 정보의 정확성과 의료적인 효과성을 보증하지 않습니다. 따라서 회사는 이와 관련되어 발생한 문제에 대해서는 책임을 지지 않습니다.</li>
		</ul>
		<h4>회원이 되시면 오케어 서비스를 더욱 다양하게 사용할 수 있습니다</h4>
		<ul>
			<li>여러분이 본 약관을 읽고 동의하시게 되면, 회사는 이에 대한 승낙을 통해 오케어 서비스 회원 가입을 완료하게 됩니다</li>
			<li>오케어 회원이 되시면, 여러 곳에 흩어져 있는 여러분의 건강 관련 데이터를 편리하고 안전하게 수집 및 보관할 수 있고, 이를 기반으로 회사 및 파트너가 제공하는 풍성하고 다채로운 서비스를 이용할 수 있습니다</li>
			<li>회사는 다양한 건강 데이터 수집과 서비스를 제공합니다. 회사는 새로운 서비스를 여러분들께 끊임없이 제공하기 위하여 불가피하게 서비스의 상세한 내용은 별도로 알려드릴 수 밖에 없습니다. 이러한 회사의 사정을 이해해주시길 바라며, 회사도 개별적인 서비스 이용방법을 앱 스토어와 서비스의 안내 및 고지 사항에서 더 상세하게 알려드리겠습니다.</li>
			<li>여러분은 오케어 서비스 가입 후에도 언제든지 오케어 서비스를 구성하는 개별 서비스 내지 파트너 서비스의 이용을 종료할 수 있으며, 회사는 이 경우 관련 법령에서 정하는 바에 따라 일정기간 보관해야 하는 정보를 제외하고는 해당 서비스 이용기록, 여러분이 작성한 게시물 등 모든 데이터는 즉시 삭제 처리됩니다.</li>
			<li>오케어 서비스 전체의 이용을 종료하고 싶은 경우에는 오케어 앱의 회원수정 메뉴의 회원탈퇴 신청을 통해 오케어 서비스 이용계약을 해지하여야 합니다.</li>
			<li>회사는 다음의 사유에 해당하는 경우, 회원 자격을 제한, 정지, 상실시킬 수 있습니다.
				<ul>
					<li>등록내용에 허위, 기재누락, 오기가 있는 경우</li>
					<li>오케어 및 파트너 서비스 이용대금, 기타 서비스 이용에 관련하여 회원이 부담하는 채무를 기일에 이행하지 않은 경우</li>
					<li>다른 사람의 오케어 서비스이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</li>
					<li>오케어를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
				</ul>
			</li>
			<li>회사는 더 나은 오케어 서비스를 제공하기 위하여 여러분에게 오케어 서비스의 이용과 관련된 각종 고지, 관리 메시지 및 기타 광고를 비롯한 다양한 정보를 오케이 서비스 내에 표시하거나 여러분의 회원 가입 정보에 등록되어 있는 연락처로 직접 발송할 수 있습니다. 단, 광고성 정보 전송의 경우에는 수신에 사전 동의한 경우에만 전송합니다.</li>
			<li>여러분이 오케어 서비스를 이용하는 과정에서 WiFi 무선인터넷을 사용하지 않고, 가입하신 이동통신사의 무선인터넷에 연결하여 이용하는 경우 이동통신사로부터 여러분에게 별도의 데이터 통신요금이 부과될 수 있는 점을 유의하여 주시기 바랍니다. 통합서비스 이용 과정에서 발생하는 데이터 통신요금은 여러분이 여러분의 비용과 책임 하에 이동통신사에 납부하셔야 합니다. 데이터 통신요금에 대한 자세한 안내는 여러분이 가입하신 이동통신사에 문의하시기 바랍니다.</li>
		</ul>
		<h4>오케어 서비스 이용을 위한 주의사항이 있습니다</h4>
		<ul>
			<li>회사 및 파트너가 제공하는 콘텐츠의 소유권과 지식재산권 등의 권리는 원칙적으로 여러분에게 있지 아니하므로, 이를 임의로 변경, 복제, 저장, 배포, 판매하는 등의 행위는 허용하지 않습니다. 오케어를 이용하시는 여러분은 본 이용약관을 준수하는 경우에 한해서만, 오케어를 개인적인 용도로 자유롭게 사용하실 수 있는 권리를 부여받습니다.</li>
			<li>여러분이 오케어 서비스 내에 게시한 게시물과 콘텐츠 등에 대한 권리는 여러분에게 있습니다. 다만, 오케어 서비스에 콘텐츠 등을 게시하는 경우에는, 회사 및 서비스 파트너 회사들에게 이러한 콘텐츠에 대한 저장, 복제, 수정, 공중 송신, 전시, 배포, 2차적 저작물 작성 등의 비독점적이고 양도 및 재허가 가능한 전세계적인 무상의 라이선스를 제공하게 됩니다. 게시물과 콘텐츠 게재로 여러분은 회사에게 그러한 권한을 부여하게 되므로, 여러분은 이에 필요한 권리를 보유하고 있어야 합니다. 여러분이 서비스의 사용을 중단하거나 오케어 서비스를 탈퇴한 후에도 해당 권리는 회사에 남게 됩니다.</li>
			<li>여러분이 부여해 주신 콘텐츠 및 게시물 이용 권한은 회사 및 파트너 서비스사에서 서비스를 운영, 개선, 홍보하고 새로운 서비스를 개발, 연구, 외부 사이트에서의 검색, 수집 및 링크 허용을 위해서만 제한된 범위에서 사용합니다. 만약, 그 밖의 목적을 위해 부득이 여러분의 콘텐츠를 이용하고자 할 경우에는 사전에 여러분께 회사 및 파트너 서비스사는 설명을 드리고 동의를 받도록 하겠습니다.</li>
			<li>오케어 서비스 내에 회사나 서비스 파트너가 게시하지 않았거나 저작권 등 관련 권한을 보유하지 않은 일부 콘텐츠가 표시될 수 있습니다. 그러한 콘텐츠에 대해서는 콘텐츠를 제공한 주체가 단독으로 모든 책임을 부담하게 됩니다. 여러분이 서비스를 이용하더라도 다른 이용자의 콘텐츠에 대하여 어떠한 권리를 가지게 되는 것은 아닙니다. 여러분이 다른 이용자의 콘텐츠를 사용하기 위해서는 콘텐츠 소유자로부터 별도의 허락을 받아야 합니다.</li>
			<li>회사는 여러분의 콘텐츠와 게시물이 타인의 명예를 훼손하거나 비방하는 내용, 불법·폭력적이거나 차별적인 내용, 음란하거나 저급한 내용, 도배, 광고, 홍보, 스팸성 게시물, 타인을 사칭하는 게시물 등이라고 판단되는 경우 이를 삭제하거나 게시를 거부할 수 있습니다.</li>
		</ul>
		<h4>회사는 여러분의 개인정보 보호를 위해 최선을 다하겠습니다.</h4>
		<ul>
			<li>회사는 서비스의 원활한 제공을 위하여, 이용자 개인이 동의한 목적과 범위 내에서만 개인정보를 수집, 이용합니다.</li>
			<li>오케어 서비스 중 일부는 사용자의 위치정보를 이용하게 됩니다. 사용자의 위치정보는 원칙적으로 오케어의 위치정보 활용 기능의 제공을 위해 ‘위치정보의 보호 및 이용 등에 관한 법률’ 등 관련 법령을 준수하여 합법적으로 이용됩니다. 개인 위치 정보의 활용과 관련한 회사와 사용자 간의 권리, 의무 관계는 회사 위치정보 이용약관에서 정한 바에 따릅니다.</li>
			<li>여러분 및 회원에 관한 개인정보를 안전하게 처리하기 위한 노력에 대한 상세한 사항은 오케어 개인정보 취급방침에서 확인하실 수 있습니다.</li>
			<li>오케어 앱을 삭제할 경우 단말 내에 저장되어 있는 오케어 및 파트너의 데이터는 삭제되지만, 오케어 서버에 저장된 데이터들은 삭제되지 않을 수 있습니다.</li>
			<li>오케어 파트너 서비스 이용기간이 종료된다고 하더라도, 오케어 서버에 이미 저장된 데이터는 삭제되지 않을 수 있으며, 파트너가 자체적으로 별도의 서버에 저장한 데이터들은 그 회사의 정책에 따라 처리되며, 그에 따른 모든 책임도 전적으로 파트너 회사가 부담하게 됩니다.</li>
		</ul>
		<h4>회사에서 제공하는 다양한 포인트를 활용해 보세요</h4>
		<ul>
			<li>회사는 여러분이 오케어 및 파트너 서비스를 효율적으로 이용할 수 있도록 포인트를 운영하고 있습니다.</li>
			<li>포인트는 오케어 서비스의 효율적 이용 및 운영을 위해 사전 공지 후 포인트의 일부 또는 전부를 조정할 수 있으며, 포인트는 회사가 정한 기간에 따라 주기적으로 소멸할 수 있습니다.</li>
			<li>회사가 정한 기준에 의해 회원에게 제공한 포인트는 회원이 이용계약을 해지하거나 회원자격이 상실된 경우 더 이상 유효하지 않습니다.</li>
			<li>포인트 제공 및 소멸 기준은 오케어 서비스를 통해 확인할 수 있습니다.</li>
		</ul>
		<h4>오케어 서비스를 중단하거나 변경할 경우 홈페이지를 통해 알려드리겠습니다.</h4>
		<ul>
			<li>회사는 연중 무휴, 1일 24시간 안정적으로 서비스를 제공하기 위해 최선의 노력을 다합니다. 다만, 사전에 공지된 정기점검, 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스 제공이 중단되거나 변경될 수도 있고, 이 경우 회사는 사전에 통지할 수 없는 부득이한 사유가 없는 한 여러분에게 그러한 사정을 사전 통지하거나 안내합니다.</li>
			<li>회사는 불가피한 사정이나 기술적 사양의 변경 등의 경우에는 서비스의 내용을 변경할 수 있습니다. 이 경우에는 변경된 서비스와 프로그램 및 제공 일자를 명시하여 현재의 서비스와 프로그램 내용을 게시한 곳에 그 제공일자 이전 7일부터 공지합니다. 해당 사유가 오케어의 고의 또는 과실로 인하여 손해가 있을 경우 관련 법령에 따라 여러분의 손해를 배상합니다.</li>
			<li>회사가 서비스 제공의 중단 내지 변경으로 인하여 손해배상책임을 부담하는 경우에도 통상적으로 예견이 불가능하거나 특별한 사정으로 인한 특별 손해 또는 간접 손해, 기타 징벌적 손해에 대해서는 관련 법령에 특별한 규정이 없는 한 책임을 부담하지 않습니다.</li>
			<li>한편, 회사는 서비스 운영 또는 개선 필요성이 있는 경우 서비스의 전부 또는 일부를 수정, 변경 또는 종료할 수 있습니다. 무료로 제공되는 서비스의 전부 또는 일부를 수정, 변경 또는 종료하게 된 경우 관련 법령에 특별한 규정이 없는 한 별도의 보상을 하지 않습니다.</li>
			<li>파트너에서 제공하는 서비스도 계약 종료 및 변경 등의 사유로 서비스의 내용이 변경되거나, 서비스가 종료될 수 있습니다. 이는 별도 공지를 통해 알려드리겠습니다.</li>
			<li>개별 서비스 또는 파트너 서비스 이용 종료 시점에 향후 일정기간 해당 서비스의 재이용에 제한이 있을 수 있다는 별도 안내가 있는 경우 해당 안내에 따라 해당 서비스의 재이용에 일정한 시간적 제한이 있을 수 있습니다.</li>
		</ul>
		<h4>회사는 이용약관 및 운영정책을 사전 공지 후 개정합니다</h4>
		<ul>
			<li>본 약관의 내용은 오케어 서비스의 화면에 게시하거나 기타의 방법으로 공지하고, 본 약관에 동의한 여러분 모두에게 그 효력이 발생합니다.</li>
			<li>회사는 필요한 경우 법령을 위배하지 않는 범위에서 본 약관을 변경할 수 있습니다. 본 약관이 변경되는 경우 회사는 변경사항을 시행일자 7일 전부터 여러분에게 서비스 공지사항에서 공지 또는 통지하는 것을 원칙으로 합니다. 다만, 이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소 30일 전부터 오케어에 등록된 이메일 주소로 이메일 발송 또는 여러분이 등록한 휴대번호로 문자메시지 발송하는 방법 등으로 개별적으로 알려드리겠습니다. 연락처 미기재, 변경 등으로 인하여 여러분에 대한 개별 통지가 어려운 경우와 여러분이 등록한 연락처로 고지를 하였음에도 2회 이상 반송된 경우 오케어 서비스 알림에 7일 이상 게시를 함으로써 개별 통지한 것으로 봅니다.</li>
			<li>회사는 전 항에 따라 공지 또는 통지하면서 공지 또는 통지일로부터 개정약관 시행일로부터 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 여러분이 명시적으로 거부 의사표시를 하지 아니한 경우 여러분이 개정약관에 동의한 것으로 봅니다.</li>
			<li>여러분이 변경된 약관에 대하여 거부의사를 표시함으로써 이용계약을 해지할 수 있습니다.</li>
			<li>본 약관은 여러분이 본 약관에 동의한 날로부터 오케어 서비스 이용 계약의 해지 시까지 적용하는 것을 원칙으로 합니다. 단, 본 약관의 일부 조항은 이용계약의 해지 후에도 유효하게 적용될 수 있습니다.</li>
			<li>본 약관에 명시되지 않은 사항은 콘텐츠산업진흥법, 전자상거래등에서의소비자보호에관한법률, 저작권법 등 관련 법령의 규정과 일반 상관례에 의합니다.</li>
			<li>회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 오케어 앱 고객센터 메뉴 내 Q&A를 운영합니다.</li>
			<li>회사는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 통보해 드립니다.</li>
			<li>본 약관 또는 오케어 서비스와 관련된 여러분과 회사와의 관계는 대한민국 법령이 적용됩니다. 그리고 본 약관 또는 오케어 서비스와 관련하여 여러분과 회사 사이에 분쟁이 발생할 경우, 그 분쟁의 처리는 대한민국 민사소송법에서 정한 절차를 따릅니다.</li>
			<li>오케어 서비스와 관련하여 궁금하신 사항이 있으시면 고객센터(대표번호 : 1544-3677 / 평일 09:00~18:00)로 문의 주시기 바랍니다.</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'agreementV10',
}
</script>