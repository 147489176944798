<script setup>
import { useMeta } from "vue-meta";
//const mode = process.env.VUE_APP_TITLE;

useMeta({
  title: "KB 오케어 신청",
  meta: [
    { vmid: "og:title", property: "og:title", content: "KB 오케어 신청" },
    {
      vmid: "og:url",
      property: "og:url",
      content: "https://www.kb-health.com",
    },
    { vmid: "og:image", property: "og:image", content: "/ogimg_apply.png" },
    {
      vmid: "og:description",
      property: "og:description",
      content: "KB 오케어 신청 설명",
    },
    { vmid: "og:image:width", property: "og:image:width", content: "800" },
    { vmid: "og:image:height", property: "og:image:height", content: "400" },
    { vmid: "twitter:title", name: "twitter:title", content: "KB 오케어 신청" },
    {
      vmid: "twitter:url",
      name: "twitter:url",
      content: "https://www.kb-health.com",
    },
    {
      vmid: "twitter:image",
      name: "twitter:image",
      content: "/ogimg_apply.png",
    },
    {
      vmid: "twitter:description",
      name: "twitter:description",
      content: "KB 오케어 신청 설명",
    },
  ],
});
</script>

<template>
  <router-view></router-view>
</template>

<style>
html {
  font-size: 10px !important;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.mdl-wrap {
  position: relative;
  width: 100%;
  max-width: calc(58rem + 4.8rem);
  margin: 0 auto;
}

.mdl-bul-help {
  position: relative;
  margin: 0;
  width: 100%;
  padding-left: 0;
  text-align: left;
  display: flex;
  font-size: 1.2rem;
  line-height: 1.4;
}

.mdl-bul-help::before {
  content: "";
  position: relative;
  min-width: 1.8rem;
  min-height: 1.8rem;
  max-width: 1.8rem;
  max-height: 1.8rem;
  border-radius: 50%;
  left: 0;
  top: -0.05em;
  margin-right: 0.4rem;
  font-weight: 200;
  font-size: 1.4em;
  line-height: 1;
  background: #ffd700
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='10' viewBox='0 0 3 10' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.41921 0.275879H1.0032L0.763203 6.62388H1.6992L2.41921 0.275879ZM1.8192 8.11188C1.6512 7.93588 1.4432 7.84388 1.1952 7.83588C0.947203 7.83588 0.731203 7.92388 0.547203 8.09988C0.371203 8.27588 0.283203 8.48388 0.283203 8.72388C0.283203 8.96388 0.371203 9.17188 0.547203 9.34788C0.723203 9.53188 0.939203 9.62388 1.1952 9.62388C1.4112 9.62388 1.6112 9.53588 1.7952 9.35988C1.9872 9.19188 2.08321 8.97988 2.08321 8.72388C2.08321 8.48388 1.9952 8.27988 1.8192 8.11188Z' fill='%23666666'/%3E%3C/svg%3E")
    no-repeat 50% 50%;
}

.app-kbocare > .mdl-wrap {
  padding: 0 2.4rem;
}

.app-kbocare header {
  width: 100vw;
  margin-left: -2.4rem;
  height: 5.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.1rem solid #eeeeee;
}

.app-kbocare footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2.1rem 2.4rem calc(env(safe-area-inset-bottom) + 3.6rem);
  background-color: #fff;
  z-index: 10;
}

.app-kbocare header h1 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.3;
}

.app-kbocare main {
  padding: 3.2rem 0 calc(env(safe-area-inset-bottom) + 15rem);
  z-index: 1;
}

.app-kbocare main h2 {
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 1.4;
  letter-spacing: -0.06rem;
  color: #000;
  margin-bottom: 1.2rem;
}

.app-kbocare main p {
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.7;
  letter-spacing: -0.06rem;
}

.app-kbocare .mdl-form {
  margin-top: 3rem;
}

.app-kbocare .mdl-form ~ .mdl-form {
  margin-top: 1.6rem;
}

.app-kbocare .mdl-btn[data-style="primary"]:disabled {
  background-color: #ccc;
  opacity: 1 !important;
}

.app-kbocare .mdl-btn[data-style="primary"]:disabled span {
  color: #999;
}

.app-kbocare input::placeholder {
  color: #999;
  font-size: 1.6rem;
  font-weight: 400;
}

.app-kbocare .mdl-bul-help {
  margin-top: 2.4rem !important;
}

.app-kbocare .mdl-section ~ .mdl-section {
  margin-top: 3.2rem;
}

@media (max-width: 374px) {
  html {
    font-size: 2.67vw !important;
  }
}

@media (min-width: 628px) {
  .app-kbocare header {
    width: 100%;
    margin-left: 0;
    border-bottom: 0.2rem solid #eeeeee;
    height: auto;
    padding: 5rem 0 2.4rem;
  }

  .app-kbocare header h1 {
    font-size: 3.2rem;
  }

  .app-kbocare main h2 {
    font-size: 2.4rem;
    margin-bottom: 1.8rem;
  }

  .app-kbocare main p {
    font-size: 2rem;
    letter-spacing: -0.06rem;
  }

  .mdl-section ~ .mdl-section {
    margin-top: 4rem;
  }

  .app-kbocare main {
    padding: 6.4rem 0 4.8rem;
  }

  .app-kbocare footer {
    position: relative;
    bottom: 0;
    padding: 0 0 11.2rem;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 10;
  }

  .app-kbocare .mdl-bul-help {
    margin-top: 3.6rem;
  }

  .app-kbocare .mdl-toast {
    max-width: 58rem;
    justify-content: flex-start;
    padding: 2.8rem 3.9rem;
    bottom: 3.6rem;
  }

  .mdl-toast > div {
    font-size: 1.8rem;
  }

  [data-icon-before="check-toast"]::before,
  [data-icon="check-toast"]::before,
  [data-icon-after="check-toast"]::after {
    width: 2.4rem;
    height: 2.4rem;
    min-width: 2.4rem;
    min-height: 2.4rem;
  }
}
</style>
