import LayoutSales from "@/layout/LayoutSales.vue";

const sales = {
  path: "/sales",
  name: "Sales",
  component: LayoutSales,
  children: [
    {
      path: "apply/:salesCode",
      alias: "/apply/:salesCode",
      name: "OneKbApply",
      props: true,
      /*
      meta: {
        requiresAuth: false,
        title: "KB 오케어 신청",
        url: "https://www.kbhc.co.kr/sales/apply/OC_4AIA14VL1O",
        image: "/ogimg_apply.png",
        desc: "KB 오케어 신청 설명"
      },
      */
      component: () => import("@/views/sales/OneKbApply.vue"),
    },
    {
      path: "error",
      children: [
        {
          path: "404",
          name: "ErrorSales404",
          component: () => import("@/views/sales/404Page.vue"),
        },
        {
          path: "500",
          component: () => import("@/views/sales/500Page.vue"),
        },
      ],
    },
    //잘못된 URL
    {
      path: ":pathMatch(.*)",
      redirect: { name: "ErrorSales404" },
    },
  ],
};
export default sales;
