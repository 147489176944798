import * as recruit from "@/api/recruit";

/**
 * 페이지 조회
 * @param {} param0
 * @param {*} param1
 */
export async function getPage({ commit }, { pageNum }) {
  const data = await recruit._getPage(pageNum);
  commit("setPage", data);
}

/**
 * 상세 조회
 * @param {} param0
 * @param {*} param1
 */
export async function getDetail({ commit }, { postNo }) {
  const data = await recruit._getDetail(postNo);
  commit("setDetail", data);
}
