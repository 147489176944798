<script setup></script>

<template>
  <div id="container">
    <div id="content" class="content">
      <div class="content-wrap">
        <div class="section">
          <div class="comp">
            <router-view :key="$route.fullPath"></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterPage />
</template>

<style scoped></style>
