import LayoutRecruit from "@/layout/LayoutRecruit.vue";

const recruit = {
  path: "recruit",
  alias: "/recruit",
  name: "",
  component: LayoutRecruit,
  meta: {
    unauthorized: true,
    menuInfo: { title: "채용", desc: "지원하세요." },
    title: "국민과 건강사이 KB헬스케어"
  },
  children: [
    {
      path: "",
      name: "RecruitPage",
      component: () => import("@/views/recruit/RecruitPage.vue"),
      meta: {
        desc: "채용안내",
      },
    },
  ],
};

export default recruit;
