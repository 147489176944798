import dayjs from "dayjs";
import store from "@/store";

export default {
  striphtml(value) {
    const div = document.createElement("div");
    div.innerHTML = value;
    return div.textContent || div.innerText || "";
  },

  limitTo(value, size) {
    if (!value || !size) return "";
    return value.substring(0, size) + "...";
  },

  snakeToHuman(value) {
    if (!value) return "";
    return value.replace(/_/g, " ");
  },

  /**
   * 날짜 형식 변환
   * https://day.js.org/docs/en/display/format
   */
  formatDate(dateString, formatter) {
    //console.debug("filter.formatDate ", dateString);
    if (dateString == null) return "no date";
    const date = dayjs(dateString);
    return date.format(formatter);
  },

  /**
   * 코드 조회
   * @param {*} cdId
   * @returns
   */
  mapCode(cdId) {
    const data = store.state.codes.filter((item) => item.cdId == cdId);

    //console.debug(data[0].cdNm);
    return data[0] || {};
  },

  /**
   * 그룹ID별 코드조회
   * @param {*} grpId
   * @param {*} cdId
   * @returns
   */
  mapCodeByGrpId(grpId, cdId) {
    const data = store.state.mapCodesByGrpId[grpId].filter(
      (item) => item.cdId == cdId
    );

    console.debug("mapCodeByGrpId", data[0].cdNm);
    return data[0] || {};
  },
};
