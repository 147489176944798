import LayoutOthers from "@/layout/LayoutOthers.vue";

const person = {
  path: "terms",
  name: "PersonOcare",
  meta: {
    unauthorized: true,
    menuInfo: {
      title: "개인정보처리방침",
      desc: "개인정보처리방침 입니다.",
    },
    subMenu: [
      {
        link: "/terms/person/ocare",
        name: "PersonOcare",
        to: "/ocare",
        title: "오케어",
        onoff: true,
      },
      {
        link: "/terms/person/ocareX",
        name: "PersonOcareX",
        to: "/ocareX",
        title: "오케어X",
        onoff: true,
      },
    ],
  },
  children: [
    {
      path: "person",
      component: LayoutOthers,
      children: [
        {
          path: "ocare",
          alias: "/ocare",
          name: "PersonOcare",
          props: true,
         
          component: () => import("@/views/person/PersonOcare.vue"),
        },
        {
          path: "ocareX",
          alias: "/ocareX",
          name: "PersonOcareX",
          props: true,
         
          component: () => import("@/views/person/PersonOcareX.vue"),
        }
      ],
    },
  ],
};

export default person;
