import { createStore } from "vuex";
import state from "./state";
import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";
import news from "./modules/news";
import recruit from "./modules/recruit";
import sales from "./modules/sales";
const store = createStore({
  state, //값
  actions, //api 통신
  mutations, //값 적용
  getters,
  modules: {
    news,
    recruit,
    sales,
  },
});

export default store;
