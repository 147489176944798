import LayoutOcare from "@/layout/LayoutOcare.vue";
import OcarePage from "@/views/ocare/OcarePage.vue"; // 추가

const main = {
  path: "",
  name: "Main",
  component: LayoutOcare,
  meta: {
    requiresAuth: false,
    title: "국민과 건강사이 KB헬스케어", 
  },
  children: [
    {
      path: "",
      name: "MainPage",
      component: OcarePage,
    },
  ],
};
export default main;
