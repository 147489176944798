<script setup></script>

<template>
  <footer id="footer" class="footer">
    <div class="footer-wrap">
      <div class="footer-logo"><span>KB 헬스케어</span></div>
      <div class="footer-link">
        <ul>
          <li><strong><router-link to="/terms/person/ocare">개인정보처리방침</router-link></strong></li>
          <li>
            <router-link to="/terms/agreement">서비스이용약관</router-link>
          </li>
          <li><strong><router-link to="/terms/localservice">위치기반서비스 이용약관</router-link></strong>
          </li>
        </ul>
      </div>
      <div class="footer-info">
        <p>
          (주)케이비헬스케어 <br class="mo-br" />대표: 최낙천 사업자등록번호:
          356-86-02394 <br class="mo-br" />통신판매업신고번호:
          2021-서울강남-06776호
        </p>
        <p class="addr">
          06212 서울특별시 강남구 테헤란로 334, 5층
          <br class="mo-br" />(교정공제회 역삼빌딩)
          <br class="mo-br" />
          대표전화: 1544-3677
        </p>
      </div>
      <div class="footer-copy">
        <span>Copyright © KB헬스케어. All rights reserved.</span>
      </div>
      <div class="footer-sns">
        <a href="https://www.youtube.com/@KBHealthCare" class="youtube" target="_blank" title="새창열림"><span>KB헬스케어 공식 유튜브
            채널 바로가기</span></a>
        <a href="https://blog.naver.com/kbhealthcare" class="blog" target="_blank" title="새창열림"><span>KB헬스케어 공식 블로그
            바로가기</span></a>
      </div>
    </div>
  </footer>
</template>

<style></style>
