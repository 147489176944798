import * as news from "@/api/news";
import * as common from "@/api/common";

const SIZE_UNIT_PAGE = 12;

/**
 * 소식 조회
 * @param {} param0
 * @param {*} param1
 */
export async function getPage({ commit }, { ntcFg, pageNum }) {
  const data = await news._getPage(ntcFg, pageNum, SIZE_UNIT_PAGE);
  data.ntcFg = ntcFg;
  commit("setPage", data);
}

/**
 * 소식 상세 조회
 * @param {} param0
 * @param {*} param1
 */
export async function getDetail({ commit }, { ntcFg, ntcNo }) {
  commit("setDetail", { ntcFg: ntcFg, data: {} });
  const [data, files] = await Promise.all([
    news._getDetail(ntcNo),
    common._getAttachFileList(ntcFg, ntcNo),
  ]);

  if (files.length > 0) {
    data.attachFileList = files;
  }

  commit("setDetail", { ntcFg: ntcFg, data: data });
}
