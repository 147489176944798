const sample = {
  path: "/sample",
  children: [
    {
      path: "kakao",
      name: "Kakao",
      component: () => import("@/views/sample/KakaoTest.vue"),
    },
  ],
};

export default sample;
