import { createApp } from "vue";
import { createMetaManager, plugin as metaPlugin } from "vue-meta";
import router from "@/router";
import store from "@/store";
import App from "@/App.vue";
import filters from "@/filters";
import VueDOMPurifyHTML from "vue-dompurify-html";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import HeaderPage from "@/components/HeaderPage.vue";
import FooterPage from "@/components/FooterPage.vue";
import "@/resources/scss/ui.common.scss";
console.debug("NODE_ENV = ", process.env.NODE_ENV);
console.debug(
  "VUE_APP_API_BASE_URL_HMPG = ",
  process.env.VUE_APP_API_BASE_URL_HMPG
);
const app = createApp(App);
const metaManager = createMetaManager();

// Vuetify
app.use(createVuetify({
  components,
  directives,
  display: {},
  theme: {},
}));

app
  .use(router)
  .use(store)
  .use(VueDOMPurifyHTML, {
    default: {
      ADD_TAGS: ["iframe"], // this one whitelists Youtube
    },
  })
  .use(metaManager)
  .use(metaPlugin) // optional, only needed for OptionsAPI (see below)
  .component("HeaderPage", HeaderPage)
  .component("FooterPage", FooterPage)
  .mount("#app");

app.config.globalProperties.$filters = filters;
app.config.globalProperties.$router = router;
// store.dispatch("getListCodeByGrpId", {
//   grpId: "SPRT_TP_CD,CAREER_TP_CD,EMP_TP_CD",
// });
