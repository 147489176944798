<template>
	<div class="terms">
		<h2>개인정보처리방침</h2>
		<h3>총칙</h3>
		<p>㈜케이비헬스케어에 제공하는 KB 오케어서비스(이하 “KB 오케어”라 함)는 고객의 정보를 소중하게 생각하며 권리보호를 위해 , 「개인정보보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를
			처리하고 안전하게 관리하고 있습니다. 이에 「개인정보보호법」 제30조에 따라 고객에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여
			다음과 같이 개인정보 처리방침을 수립∙공개합니다.</p>
		<p>KB헬스케어의 KB 오케어는 고객의 개인정보를 보호하기 위하여 아래와 같이 개인정보처리방침을 가지고 있습니다.</p>
		<h4>1. 개인정보의 수집 항목 및 이용목적</h4>
		<ol>
			<li>① KB 오케어에서 처리한 개인정보는 다음의 목적 이외의 용도로 사용되지 않으며 이용 목적이 변경되는 경우에도 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할
				예정입니다.</li>
			<li>
				② 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 아래와 같은 개인정보를 수집하고 있습니다.
				<div class="table-wrap">
					<table>
						<caption>개인정보 처리업무, 수집 ∙ 이용 목적, 개인정보 수집 ∙ 이용 항목, 보유기간 항목으로 이루어진 테이블입니다.</caption>
						<colgroup>
							<col width="15%">
							<col width="17%">
							<col width="auto">
							<col width="13%">
						</colgroup>
						<thead>
							<tr>
								<th scope="col">개인정보 처리업무</th>
								<th scope="col">수집 ∙ 이용 목적</th>
								<th scope="col">개인정보 수집 ∙ 이용 항목</th>
								<th scope="col">보유기간</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>B2B회원사 회원 관리</td>
								<td>오케어서비스 이용을 위한정보 등록</td>
								<td>[필수] 회사명, 성명, 사번, 회사이메일[선택] 없음</td>
								<td>위탁계약종료시</td>
							</tr>
							<tr>
								<td>고객사 관리</td>
								<td>오케어서비스 기업 담당자 정보 관리</td>
								<td>[필수] 기업명, 기업ID, 담당자명, 담당자 휴대전화번호, 법인계좌번호[선택] 없음</td>
								<td>위탁계약종료시</td>
							</tr>
							<tr>
								<td>입점사 관리</td>
								<td>오케어서비스 입점사 담당자 정보 관리</td>
								<td>[필수] 기업명, 기업ID, 담당자명, 담당자 휴대전화번호, 법인계좌번호[선택] 없음</td>
								<td>사업계약 종료시</td>
							</tr>
							<tr>
								<td rowspan="2">회원 관리</td>
								<td>본인확인</td>
								<td>[필수] 성명, 생년월일, 성별, 휴대전화번호, CI값[선택] 없음</td>
								<td>본인확인후 파기</td>
							</tr>
							<tr>
								<td>회원가입 및 관리</td>
								<td>
									[필수] 성명, 생년월일, 성별, 휴대전화번호, CI값, 회사명, 이메일, 사번<br>
									* 회사 별 수집하는 정보 일부 상이 할 수 있음<br>
									[선택] 없음</td>
								<td rowspan="16">
									회원 탈퇴 시 또는 위탁계약종료시 <br>
									*다만, 관계법령에 의해 보존할 경우 그 의무기간 동안<br>
									별도 보관
								</td>
							</tr>
							<tr>
								<td rowspan="13">건강관리서비스제공</td>
								<td>미래 건강 예측 서비스 제공 및 및 건강기록</td>
								<td>
									[필수] 성명, 기타 의료적 요인(의사권고사항 행위여부),<br>
									생활습관(금연여부), 혈압수치, 혈당수치, 체중, 콜레스테롤<br>
									수치, 걸음수, 체중, 식사, 허리둘레, 심박, 복약,<br>
									혈압, 당수치, 운동, 수면<br>
									[선택] 없음
								</td>
							</tr>
							<tr>
								<td>건강뉴스 구독</td>
								<td>
									[필수] 성명, 대사질환, 나의식습관, 생활패턴<br>
									[선택] 없음
								</td>
							</tr>
							<tr>
								<td>서비스 홍보 및 광고성 정보 제공</td>
								<td>
									[필수] 없음<br>
									[선택] 성명, 이메일, 휴대전화번호
								</td>
							</tr>
							<tr>
								<td>(일반검진)건강검진결과 조회 및 검진결과 기반 건강정보/증진서비스 제공</td>
								<td>
									[필수] 회사명, 이름, 검진기관명, 검진일, 신체계측[신장, 체중,<br>
									허리둘레,체질량지수(BMI), 수축/이완기혈압]<br>
									혈액검사[혈색소, 공복시혈당(FBS),순환기(총콜레스테롤,<br>
									HDL-콜레스테롤, 중성지방(TG), 신장요로(크레아티닌,신사구체여과율)<br>
									* 정보주체별 수집하는 정보가 상이할 수 있음<br>
									[선택] 없음
								</td>
							</tr>
							<tr>
								<td>(종합검진) 건강검진결과 조회 및 검진결과 기반 건강정보/증진서비스 제공</td>
								<td>
									[필수] 없음<br>
									[선택] 이름, 생년월일, 성별, 사번, 휴대전화번호,검진년도, 검진일자, 신장, 체중, 허리둘레, BMI, 시력(좌/우), 청력(좌/우),
									혈압(수축기혈압/확장기혈압), 요단백, 혈색소, 식전혈당, 총콜레스테롤, HDL콜레스테롤, 트리클리세라이드, LDL콜레스테롤, 혈청크레아티닌,
									신사구체여과율(GFR), AST(SGOT), ALT(SGPT), 감마지피티(y-GPT), 골다공증<br>
									* 정보주체 별 수집하는 정보가 상이할 수 있음
								</td>
							</tr>
							<tr>
								<td>KB부속병원 비대면 진료예약 대행 및 예약내역 조회서비스</td>
								<td>
									[필수] 이름, 휴대전화번호, 병원명, 희망진료일시, 증상, CI값<br>
									[선택] 없음
								</td>
							</tr>
							<tr>
								<td>
									KB부속병원<br>
									대면진료예약 대행 및 예약내역 조회서비스
								</td>
								<td>
									[필수] 이름, 휴대전화번호, 병원명, 희망진료일시, 증상,<br>
									[선택] 없음
								</td>
							</tr>
							<tr>
								<td>
									일반병원<br>
									비대면 진료예약 대행 및 예약내역 조회서비스
								</td>
								<td>
									[필수] 이름, 휴대전화번호, 병원명, 희망진료일시, 증상, CI값<br>
									[선택] 없음
								</td>
							</tr>
							<tr>
								<td>증상 유사도 분석 서비스</td>
								<td>
									[필수] 이름, 성별, 증상 유사도 분석 결과<br>
									[선택] 없음
								</td>
							</tr>
							<tr>
								<td>헬스장 이용상태 표시 및 출석확인서비스</td>
								<td>
									[필수] 가입ID, 이용권 상품코드, 이용권 상태시작일, 종료일), 사용자 출석이력<br>
									[선택] 없음
								</td>
							</tr>
							<tr>
								<td>유전자 검사결과 조회서비스</td>
								<td>
									[필수] 성명, 휴대전화번호, 이메일주소, 유전자 검사 결과, 건강문진표<br>
									[선택] 없음
								</td>
							</tr>
							<tr>
								<td>간병서비스 이용현황 조회서비스</td>
								<td>
									[필수] 성명, 생년월일, 이메일, 회사명, 성별, 휴대전화번호<br>
									[선택] 없음
								</td>
							</tr>
							<tr>
								<td>단체급식메뉴 조회 및 식단관리 정보제공</td>
								<td>
									[필수] 성명, 성별, 생년월일, 회사명, 키, 체중, 선택한 단체급식 및 칼로리<br>
									[선택] 없음
								</td>
							</tr>
							<tr>
								<td>1:1문의</td>
								<td>1:1문의에 대한 답변 및 문의내역 관리 및 조회</td>
								<td>
									[필수] 문의유형, 회원명, 고객사, 답변결과<br>
									[선택] 없음
								</td>
							</tr>
							<tr>
								<td>결제서비스</td>
								<td>
									대금결재/환불서비스제공,<br>
									주문/배송/거래 내역조회서비스 제공
								</td>
								<td>
									[필수] 성명, 휴대전화번호, 주문/배송/거래(결제)내역<br>
									[선택] 없음
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</li>
			<li>③ KB 오케어는 만 14세 이상의 이용장[ 한하여 서비스(회원가입 등)를 제공하는 것을 원칙으로 합니다.</li>
			<li>
				④ 모바일 앱 서비스를 위하여 아래와 같이 이용자의 이동통신단말기 내 정보 및 기능에 접근, 이용할 수 있습니다. 모바일 앱 서비스에서 요청하는 모든 요청권한은 선택적 접근 권한으로 선택
				접근항목은 해당 서비스 최초 이용 시 별도 동의를 받습니다. 선택 접근항목의 경우 OS버전에 따라 동의 받는 방법이 다를 수 있으나, 회원이 거부하더라도 기본 서비스 이용에는 제한이 없습니다.
				<div class="table-wrap">
					<table>
						<caption>구분, 접근권한, 접근항목, 이용목적, 비고 항목으로 이루어진 테이블입니다.</caption>
						<colgroup>
							<col width="15%">
							<col width="15%">
							<col width="15%">
							<col width="auto">
							<col width="20%">
						</colgroup>
						<thead>
							<tr>
								<th scope="col">구분</th>
								<th scope="col">접근권한</th>
								<th scope="col">접근항목</th>
								<th scope="col">이용목적</th>
								<th scope="col">비고</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>AOS</td>
								<td>필수</td>
								<td>전화</td>
								<td>본인인증</td>
								<td rowspan="8">
									※ 선택적 접근권한은<br>
									동의하지 않아도 기본 서비스 이용이 가능
								</td>
							</tr>
							<tr>
								<td>AOS</td>
								<td>필수</td>
								<td>저장공간</td>
								<td>앱리소스 저장</td>
							</tr>
							<tr>
								<td>iOS, AOS</td>
								<td>선택</td>
								<td>카메라/사진</td>
								<td>식단사진, 프로필 이미지 등록, 비대면 진료 시 증상 이미지 촬영 및 등록</td>
							</tr>
							<tr>
								<td>iOS, AOS</td>
								<td>선택</td>
								<td>블루투스</td>
								<td>Wearable 기기연동</td>
							</tr>
							<tr>
								<td>iOS, AOS</td>
								<td>선택</td>
								<td>GPS</td>
								<td>병원 방문 진료예약, 약국찾기, 오늘의 건강날씨</td>
							</tr>
							<tr>
								<td>iOS, AOS</td>
								<td>선택</td>
								<td>Face ID/지문</td>
								<td>사용자 인증</td>
							</tr>
							<tr>
								<td>iOS, AOS</td>
								<td>선택</td>
								<td>마이크</td>
								<td>화상상담(동영상촬영, 음성녹음)</td>
							</tr>
							<tr>
								<td>AOS</td>
								<td>선택</td>
								<td>신체활동</td>
								<td>내 건강기록 서비스</td>
							</tr>
						</tbody>
					</table>
				</div>
			</li>
		</ol>
		<p>※ KB 오케어 서비스 앱 접근권한 동의 또는 철회 방법</p>
		<ul>
			<li>안드로이드 계열 : 설정→애플리케이션→ ‘오케어’ 앱 선택 → 권한 → 접근 항목별 ‘허용’ 또는 ‘허용 안함’ 체크</li>
			<li>iOS계열 : 설정 → ‘오케어’ 앱 선택 → 접근권한 별 동의 또는 철회 체크</li>
		</ul>
		<h4>2. 개인정보의 이용 및 보유기간</h4>
		<p>KB 오케어는 이용자의 개인정보를 고지 및 동의 받은 사항에 따라 처리목적 달성, 보유기간의 경과 등 개인정보가 불필요하게 되었을 경우 지체없이 해당 개인정보를 파기합니다. 단, 다음과 같은 사유에
			해당하는 경우 기재된 기한까지 개인정보를 외부와 차단된 별도 DB 또는 테이블에 분리 보관됩니다.</p>
		<ol>
			<li>① KB 오케어 회원 정보: 회원탈퇴 시 또는 위탁계종료 시</li>
			<li>
				② 관련 법령에 의한 개인정보 보관
				<div class="table-wrap">
					<table>
						<caption>수집항목, 이용목적, 근거법률, 보유기간 항목으로 이루어진 테이블입니다.</caption>
						<colgroup>
							<col width="25%">
							<col width="auto">
							<col width="15%">
							<col width="15%">
						</colgroup>
						<thead>
							<tr>
								<th scope="col">수집항목</th>
								<th scope="col">이용목적</th>
								<th scope="col">근거법률</th>
								<th scope="col">보유기간</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>접속일, 접속IP, 접속기기</td>
								<td>수사기관제공(법원 영장 등 정당한 절차의 경우에 제공)</td>
								<td>통신비밀보호법</td>
								<td>3개월</td>
							</tr>
							<tr>
								<td>고객식별정보, 분쟁처리기록, 계약 철회기록</td>
								<td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
								<td rowspan="2">전자상거래법</td>
								<td>3년</td>
							</tr>
							<tr>
								<td>계약 및 철회 기록, 대금결제 기록</td>
								<td>
									대금 결제 및 재화 등의 공급에 관한 기록<br>
									계약 또는 철회 등에 관한 기록
								</td>
								<td>5년</td>
							</tr>
						</tbody>
					</table>
				</div>
			</li>
			<li>
				③ 서비스 이용과정에서 자동으로 생성되는 정보
				<div class="table-wrap">
					<table>
						<caption>수집 및 이용목적, 수집 항목, 보유기간 항목으로 이루어진 테이블입니다.</caption>
						<colgroup>
							<col width="20%">
							<col width="auto">
							<col width="20%">
						</colgroup>
						<thead>
							<tr>
								<th scope="col">수집 및 이용목적</th>
								<th scope="col">수집 항목</th>
								<th scope="col">보유기간</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>본인확인</td>
								<td>본인확인값(CI)</td>
								<td rowspan="2">회원 탈퇴 시 또는 법정 의무 보유기간까지</td>
							</tr>
							<tr>
								<td>
									서비스 이용 통계 및<br>
									부정이용확인 및 방지
								</td>
								<td>모바일기기OS정보( OS종류 및 버전, 디바이스종류)서비스 이용시 UUID(임의 생성한 앱 고유값),푸쉬토큰(생성값), 회원의 경우 ID</td>
							</tr>
						</tbody>
					</table>
				</div>
			</li>
			<li>
				④ 개인정보 유효기간제
				<ul>
					<li>KB 오케어에서 제공하는 서비스에 이용자가 선택한 개인정보 보유기간(1년)동안 로그인 등 이용 기록이 없을 경우, 이용자의 계정을 휴면계정 처리하고 일반 이용자의 개인정보와 별도
						분리∙저장됩니다.</li>
					<li>휴면 계정처리 예정일로부터 30일 이전에 해당 사실을 전자메일, 서면, SMS 중 하나의 방법으로 이용자에게 통지합니다.</li>
					<li>분리∙저장된 개인정보는 관련 법령에 특별한 규정이 있는 경우를 제외하고 해당 개인정보를 이용하거나 제공하지 않으며, 관련 법령에 따라 일정기간 동안 보유되고 해당 기간 종료 후
						파기됩니다. 그러나, 파기되지 않은 개인정보는 해당 이용자의 요청에 따라 서비스 이용을 재개하는 시점에 다시 제공됩니다.</li>
				</ul>
			</li>
		</ol>
		<h4>3. 개인정보의 3자제공</h4>
		<ol>
			<li>① KB 오케어는 정보주체의 동의, 법률의 특별한 규정 등 개인정보보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
				<ul>
					<li>이용자가 서비스 이용중 제3자 제공에 동의한 경우</li>
				</ul>
			</li>
			<li>
				② 개인정보 3자 제공 업체
				<div class="table-wrap">
					<table>
						<caption>개인정보 제공받는자, 개인정보 이용목적, 제공하는 항목 보유 및 이용기간 항목으로 이루어진 테이블입니다.</caption>
						<colgroup>
							<col width="18%">
							<col width="27%">
							<col width="auto">
							<col width="18%">
						</colgroup>
						<thead>
							<tr>
								<th scope="col">개인정보 제공받는자</th>
								<th scope="col">개인정보 이용목적</th>
								<th scope="col">제공하는 항목</th>
								<th scope="col">보유 및 이용기간</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>㈜테라젠바이오</td>
								<td>
									<ol>
										<li>1. 유전자 검사 분석/상담서비스 및 키트 배송</li>
										<li>2. 청약의사의 확인, 거래이행, 배송, 고객상담 및 AS불만처리</li>
										<li>3. 1:1 문의 및 답변</li>
									</ol>
								</td>
								<td>
									1. 유전자 검사 분석/상담서비스 및 키트 배송<br>
									- 성명, 생년월일, 휴대전화번호, 이메일, 주소<br>
									2. 2. 청약의사의 확인, 거래이행, 배송, 고객상담 및 AS불만처리<br>
									- 성명, 휴대전화번호, 주문/거래(결제)내역<br>
									3. 1:1 문의<br>
									- 성명, 휴대전화번호, 문의내용
								</td>
								<td rowspan="40">이용목적 달성 시</td>
							</tr>
							<tr>
								<td>㈜휴노</td>
								<td>
									<ol>
										<li>1. 대면상담서비스</li>
										<li>2. 비대면상담서비스</li>
										<li>3. 심리검사 및 결과확인서비스</li>
										<li>4. 심리검사 및 결과확인+ 비대면상담서비스</li>
										<li>5. 청약의사의 확인, 거래이행, 고객상담 및 AS불만처리</li>
									</ol>
								</td>
								<td>
									1. 대면상담서비스<br>
									- 성명, 생년월일, 성별, 휴대폰 번호, 이메일, 선택한 상담센터, 상담희망예정일, 상담주제, 상담요청사항<br>
									2. 비대면상담서비스<br>
									- 성명, 생년월일, 성별, 휴대전화번호, 이메일, 선택한 상담사, 상담희망예정일<br>
									3. 심리검사 및 결과확인서비스<br>
									- 성명, 생년월일, 성별, 휴대전화번호, 이메일<br>
									4. 심리검사 및 결과확인+ 비대면상담서비스<br>
									- 성명, 생년월일, 성별, 휴대전화번호, 이메일, 선택한 상담사, 상담희망예정일<br>
									5. 청약의사의 확인, 거래이행, 고객상담 및 AS불만처리<br>
									- 성명, 휴대전화번호, 주문/거래(결제)내역
								</td>
							</tr>
							<tr>
								<td>㈜AAI헬스케어</td>
								<td>
									<ol>
										<li>1. 청약의사의 확인, 거래이행, 배송, 고객상담 및 AS불만처리</li>
										<li>
											2. 당뇨 관리 서비스 제공<br>
											(1:1상담 및 Q&A상담 등)
										</li>
										<li>3. 1:1 문의</li>
									</ol>
								</td>
								<td>
									1. 거래이행, 배송, 고객상담 및 AS불만처리<br>
									- 성명, 휴대전화번호, 주문/거래(결제)내역<br>
									2. 당뇨관리서비스<br>
									- 성명, 성별, 나이, 휴대전화번호, 건강습관실천단계설문결과, 자기주도 위기극복역량검사결과, 희망목표설정정보(혈당, 체중, 당화혈색소, 흡연여부, 음주 횟수)
									상담시 상담일시 및 상담, 식단정보, 걸음수, 복약기록정보, 건강행동패턴평가, 수면시간, 건강검진정보, 혈압<br>
									3. 1:1문의<br>
									- 성명, 휴대전화번호, 문의내용
								</td>
							</tr>
							<tr>
								<td>㈜아이센스</td>
								<td>
									건강관리기기 배송<br>
									(1:1 문의 및 답변)
								</td>
								<td>성명, 휴대전화번호, 주소, 문의내용</td>
							</tr>
							<tr>
								<td>㈜휴레이포지티브</td>
								<td>
									<ol>
										<li>1. 체중관리서비스</li>
										<li>2. 만성질환관리서비스</li>
										<li>3. 청약의사의 확인, 거래이행, 배송, 고객상담 및 AS불만처리</li>
										<li>4. 1:1문의 및 답변</li>
									</ol>
								</td>
								<td>
									1.체중관리서비스<br>
									- 성명, 휴대전화번호, 주문/거래(결제)내역, 기초대사량 근육량, 체지방, BMI, 체수분, 체중, 내장지방, 단백질, 골량, 신체나이<br>
									2. 만성질환관리서비스<br>
									- 성명, 성별, 생년월일, 핸드폰번호, 기초설문조사(심뇌혈관 위험도, 건강관리, 식습관, 개선의지 및 식습관 목표),기타 건강정보(신장, 체중, 혈압, 식사,
									운동, 걸음수, 수면, 다바이스 측정정보(혈당, 혈압, 맥박, 체성분)<br>
									3.거래이행, 고객상담, AS불만처리<br>
									- 성명, 휴대전화번호, 주문/거래(결제)내역<br>
									4. 1:1문의<br>
									- 성명, 휴대전화번호, 문의내용
								</td>
							</tr>
							<tr>
								<td>㈜GC케어</td>
								<td>건강검진 예약/평가/결과확인 서비스</td>
								<td>이메일주소 본인확인값(CI)</td>
							</tr>
							<tr>
								<td>㈜케어닥</td>
								<td>간병서비스 제공 및 1:1문의</td>
								<td>성명, 생년월일, 성별, 이메일, 회사명, 휴대전화번호, 문의내용</td>
							</tr>
							<tr>
								<td>㈜사운드짐</td>
								<td>운동영상 컨텐츠 제공 및 청약의사의 확인, 거래이행, 배송, 고객상담 및 AS불만처리</td>
								<td>성명, 휴대전화번호, 주문/거래(결제)내역</td>
							</tr>
							<tr>
								<td>㈜케이디스포츠</td>
								<td>
									<ol>
										<li>1. 스포애니 회원가입여부확인</li>
										<li>2. 주문상품예약, 고객상담 및 불만처리</li>
									</ol>
								</td>
								<td>
									1. 스포애니 회원가입여부확인<br>
									- 이름, 휴대전화번호<br>
									2. 주문상품예약, 고객상담 및 불만처리<br>
									- 성명, 휴대전화번호, 이용권(쿠폰)상품코드, 주문/거래 (결제)내역
								</td>
							</tr>
							<tr>
								<td>㈜아워홈</td>
								<td>단체급식메뉴 조회 및 식단관리 정보 제공</td>
								<td>성명 성별, 생년월일, 회사명, 키, 체중, 메인매뉴코드, 칼로리</td>
							</tr>
							<tr>
								<td>㈜비즈마켓</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜와이다</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜유니템아이</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜넥서스더마켓</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>메일헬스뉴트리션㈜</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜브이티코퍼레이션</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>풀무원건강생활㈜</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜포톤트리</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜네츄럴메이드</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜아모레퍼시픽</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜잇마플</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜구문</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜애니온넷</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜아이센스비즈</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜에덴룩스</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜제이앤제이테크놀로지</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜비컨</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜벨바이오</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜비타믹스</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜메디스턴</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜유한건강생활</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜거성디지털</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>광동생활건강(주</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>(주)헬스맥스</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜지룩</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜케이스코</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>보령컨슈머헬스케어㈜</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>㈜탱그램팩토리</td>
								<td>물품배송</td>
								<td>주문자(성명, 휴대전화번호), 수령자(성명, 휴대전화번호), 주소, 상품구매정보</td>
							</tr>
							<tr>
								<td>
									(올라케어서비스)<br>
									㈜블루앤트
								</td>
								<td>비대면 진료예약대행</td>
								<td>이름, 휴대전화번호, 희망진료일시, CI값</td>
							</tr>
						</tbody>
					</table>
				</div>
			</li>
			<li>
				③ 개인정보의 국외 이전
				<div class="table-wrap">
					<table>
						<caption>이전되는 개인정보항목, 이전, 이전일시, 이전되는 국가, 이전받는자의 개인정보 이용목적, 이전받는자의 정보관리책임자연락처, 이전받는자의 성명, 이전받는자의 개인정보 보유
							및 이용기간 항목으로 이루어진 테이블입니다.</caption>
						<colgroup>
							<col width="auto">
							<col width="8%">
							<col width="10%">
							<col width="8%">
							<col width="12%">
							<col width="12%">
							<col width="16%">
							<col width="16%">
						</colgroup>
						<thead>
							<tr>
								<th scope="col">이전되는 개인정보항목</th>
								<th scope="col">이전</th>
								<th scope="col">이전일시</th>
								<th scope="col">이전되는 국가</th>
								<th scope="col">이전받는자의 개인정보 이용목적</th>
								<th scope="col">이전받는자의 정보관리책임자연락처</th>
								<th scope="col">이전받는자의 성명</th>
								<th scope="col">이전받는자의 개인정보 보유 및 이용기간</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>주문자/받으실분(이름, 휴대전화번호), 휴대전화번호, 주소, 개인통관고유번호</td>
								<td>온라인</td>
								<td>결제완료시</td>
								<td>독일</td>
								<td>구매대행, 주문상품의 배송(예약), 고객상담 및 불만처리</td>
								<td>0049-69-710-456-233</td>
								<td>
									AE Holding GmbH<br>
									(정용준)
								</td>
								<td>구매확정후 3개월까지</td>
							</tr>
						</tbody>
					</table>
				</div>
			</li>
		</ol>
		<h4>4. 개인정보의 처리 위탁</h4>
		<ol>
			<li>① KB 오케어는 서비스 향상을 위하여 개인정보를 제한된 범위에서 업체에 위탁하여 관리하도록 할 수 있습니다.</li>
			<li>② KB 오케어는 관계 법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다. 또한, 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보
				처리방침을 통하여 공개하도록 하겠습니다.
				<table>
					<caption>수탁자, 서비스 내용, 보유 및 이용기간 항목으로 이루어진 테이블입니다.</caption>
					<colgroup>
						<col width="30%">
						<col width="auto">
						<col width="30%">
					</colgroup>
					<thead>
						<tr>
							<th scope="col">수탁자</th>
							<th scope="col">서비스 내용</th>
							<th scope="col">보유 및 이용기간</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>유라클</td>
							<td>O-CARE 개발 및 유지보수</td>
							<td>위탁 계약 종료시</td>
						</tr>
						<tr>
							<td>MS AZURE</td>
							<td>클라우드 IT인프라 제공 데이터 처리 및 저장</td>
							<td>위탁 계약 종료시</td>
						</tr>
						<tr>
							<td>메가존클라우드㈜</td>
							<td>클라우드 운영(AZURE)</td>
							<td>위탁 계약 종료시</td>
						</tr>
						<tr>
							<td>KCB</td>
							<td>본인확인서비스</td>
							<td>위탁 계약 종료시</td>
						</tr>
						<tr>
							<td>KG이니시스</td>
							<td>온라인결제서비스</td>
							<td>관련법령에 따른 5년 보존 후 파기</td>
						</tr>
						<tr>
							<td>비즈마켓</td>
							<td>커머스 플랫폼 운영 및 개발 유지보수</td>
							<td>위탁 계약 종료시</td>
						</tr>
					</tbody>
				</table>
			</li>
		</ol>
		<h4>5. 개인정보의 파기 절차 및 방법</h4>
		<ol>
			<li>① KB 오케어는 개인정보보호책임자의 책임 하에 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</li>
			<li>② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 외부와 차단된 별도
				DB 또는 테이블에 분리하여 보관합니다.</li>
			<li>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
				<p>- 파기절차</p>
				<p>수집∙이용 목적이 달성된 개인정보는 지체 없이 파기되며, 휴면회원으로 전환된 회원의 개인정보는 별도의 또는 테이블에 옮겨져 5년 동안 안전하게 보관된 후 지체없이 파기합니다.</p>
				<p>- 파기방법</p>
				<p>∙ 전자적 파일 형태로 기록∙저장된 개인정보 : 기록을 재생할 수 없도록 파기</p>
				<p>∙ 종이 문서에 기록∙저장된 개인정보 : 분쇄기로 분쇄하거나 소각하여 파기</p>
			</li>
		</ol>
		<h4>6. 이용자의 권리∙의무 및 행사방법</h4>
		<ol>
			<li>① 이용자는 개인정보 보호법 제35조에 따른 개인정보의 열람∙정정∙처리정지∙삭제 청구를 이메일, 전화, 방문 등의 방법으로 아래의 부서에 할 수 있습니다. 회사는 이용자의 개인정보
				열람∙정정∙처리정지∙삭제 청구가 신속하게 처리되도록 노력하겠습니다.
				<table>
					<caption>개인정보 민원처리 담당부서 테이블입니다.</caption>
					<colgroup>
						<col width="auto">
					</colgroup>
					<thead>
						<tr>
							<th scope="col">개인정보 민원처리 담당부서</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>기획실 정보보호유닛</td>
						</tr>
						<tr>
							<td>이메일: <a href="mailto:privacy@kbhc.co.kr">privacy@kbhc.co.kr</a></td>
						</tr>
						<tr>
							<td>전화: 02-2135-5062</td>
						</tr>
					</tbody>
				</table>
			</li>
			<li>② 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</li>
			<li>③ 이용자의 권리에 따른 열람의 요구, 정정∙삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한자가 본인이거나 정당한 대리인인지를 확인합니다.
				<p>[개인정보보호법 시행규칙 별지 제8호] 개인정보(열람, 정정∙삭제, 처리정지) 요구서</p>
				<p>[개인정보보호법 시행규칙 별지 제11호] 위임장</p>
			</li>
			<li>④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 이용자의 권리가 제한될 수 있습니다.</li>
			<li>⑤ 이용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의
				도용 등 허위 정보를 입력할 경우 회원 자격이 상실될 수 있습니다.</li>
			<li>⑥ 이용자는 언제든지 『마이페이지』 메뉴를 통해 자신의 개인정보를 조회하거나 수정할 수 있습니다.</li>
		</ol>
		<h4>7. 개인정보의 자동 수집 장치의 설치∙운영 및 거부에 관한 사항</h4>
		<p>KB 오케어는 서비스 이용과정에서 이용자로부터 다음과 같은 정보들이 자동으로 생성∙수집되고 다음의 목적으로 이용될 수 있습니다.</p>
		<table>
			<caption>개인정보 자동수집정보 사용목적, 개인정보 자동수집안내, 개인정보 자동수집 거부방법(예시), 항목으로 이루어진 테이블입니다.</caption>
			<colgroup>
				<col width="auto">
			</colgroup>
			<tbody>
				<tr>
					<th scope="col">개인정보 자동수집정보 사용목적</th>
				</tr>
				<tr>
					<td>
						KB 오케어는 관련법규의 준수를 위해 이용자의 접속기록(로그인)기록을 보관할 의무가 있습니다.<br>
						KB 오케어는 이용자의 이용기록을 분석하여 서비스 개선
					</td>
				</tr>
				<tr>
					<th scope="col">개인정보 자동수집안내</th>
				</tr>
				<tr>
					<td>
						앱을 통해 서비스를 실행 시 기기식별 고유번호(예: 장치 ID 등), 단말기 정보를 자동으로 수집하게 됩니다. 이용자가 기기<br>
						식별 고유번호를 자동으로 수집하는 것을 거부하는 경우 회사에서 제공한 서비스를 이용하실 수 없습니다.
					</td>
				</tr>
				<tr>
					<th scope="col">개인정보 자동수집 거부방법(예시)</th>
				</tr>
				<tr>
					<td>
						<ul>
							<li>Internet Explorer의 경우: 웹 브라우저 상단의 도구 메뉴 > 인터넷 옵션 > 개인정보 > 설정</li>
							<li>Chrome의 경우: 웹 브라우저 우측의 설정 메뉴 > 화면 하단의 고급 설정 표시 > 개인정보의 콘텐츠 설정 버튼 > 쿠키</li>
							<li>Edge 를 사용하는 경우 : [설정 및 기타] 메뉴 > [설정] > [쿠키 및 사이트 권한] > [쿠키 및 사이트 데이터] </li>
							<li>안드로이드 폰: 휴대폰 설정 > 앱 관리> KB 오케어 > 저장소 > 사용자데이터/캐시 > 데이터 지우기</li>
							<li>아이폰: 설정 > 앱 관리 > KB 오케어 > 저장소 > 데이터 지우기</li>
						</ul>
					</td>
				</tr>
			</tbody>
		</table>
		<h4>8. 개인정보의 안전성 확보조치</h4>
		<p>KB 오케어는 「개인정보보호법」 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.</p>
		<ol>
			<li>① 내부관리계획의 수립 및 시행
				<p>개인정보 내부관리계획 수립 및 시행은 ㈜케이비헬스케어의 내부관리 지침을 준수하여 시행합니다.</p>
			</li>
			<li>② 개인정보 취급 담당자의 최소화 및 교육
				<p>개인정보를 취급하는 담당자를 지정하고 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.</p>
			</li>
			<li>③ 개인정보에 대한 접근 제한
				<p>개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
					접근을 통제하고 있습니다.</p>
			</li>
			<li>④ 접속기록의 보관 및 위변조 방지
				<p>개인정보처리시스템에 접속한 기록을 최소 3년 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.</p>
			</li>
			<li>⑤ 개인정보의 암호화
				<p>이용자의 개인정보는 암호화되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.</p>
			</li>
			<li>⑥ 해킹 등에 대비한 기술적 대책
				<p>㈜케이비헬스케어는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신∙점검을 하며 외부로부터 접근이 통제된 구역에 시스템을
					설치하고 기술적/물리적으로 감시 및 차단하고 있습니다. 또한 네트워크 트래픽의 통제(Monitoring)는 물론 불법적으로 정보를 변경하는 등의 시도를 탐지하고 있습니다.</p>
			</li>
			<li>⑦ 비인가자에 대한 출입 통제
				<p></p>㈜케이비헬스케어의 모든 전산 시스템은 Cloud 서비스를 이용하고 있습니다.
				<p></p>㈜케이비헬스케어의 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다.
				<ul>
					<li>이용자 개개인이 본인의 개인정보를 보호하기 위해서 자신의 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.</li>
					<li>그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 분실, 도난, 유출, 위∙변조 또는 훼손될 경우 회사는 즉각 이용자에게 사실을 알리고 적절한 대책과 보상을 강구할
						것입니다.</li>
				</ul>
			</li>
		</ol>
		<h4>9. 링크사이트</h4>
		<ol>
			<li>① KB 오케어는 회원에게 다른 회사의 웹사이트(WEB View) 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 KB 오케어는 외부사이트와 자료에 대해 아무런 통제권이 없으므로 해당
				사이트(WEB View)에서 제공받는 서비스나 자료의 진실성, 유용성 등에 대해 책임지지 않으며, 어떠한 보증도 하지 않습니다.</li>
			<li>② KB 오케어에서 포함하고 있는 링크를 클릭하여 다른 사이트(WEB View)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 회사와 무관하므로 새로 방문한 사이트의 정책을 반드시
				검토하시기 바랍니다.</li>
		</ol>
		<h4>10. 광고성 정보 전송</h4>
		<ol>
			<li>① KB 오케어는 이용자의 명시적인 수신 거부 의사에 반하여 영리 목적의 광고성 정보를 전송하지 않습니다.</li>
			<li>② KB 오케어는 이용자가 뉴스레터 등 전자 우편 전송에 대한 동의를 한 경우 전자 우편의 제목란 및 본문란에 다음 사항과 같이 이용자가 쉽게 알아볼 수 있도록 조치합니다.
				<ul>
					<li>전자 우편의 제목란: 전자 우편 본문란의 주요 내용을 표시합니다.</li>
					<li>전자 우편의 본문란
						<p>가. 이용자가 수신 거부의 의사표시를 할 수 있는 전송 자의 명칭, 전자우편주소, 전화번호 및 주소를 명시합니다.</p>
						<p>나. 이용자가 수신 거부의 의사를 쉽게 표시할 수 있는 방법을 한글 또는 영문으로 각각 명시합니다.</p>
						<p>다. 이용자가 동의를 한 시기 및 내용을 명시합니다.</p>
					</li>
				</ul>
			</li>
			<li>③ 팩스, 휴대폰 문자 전송 등 전자 우편 이외의 문자 전송을 통해 영리 목적의 광고성 정보를 전송하는 경우에는 전송 내용 처음에 "(광고)"라는 문구를 표기하고 전송 내용 중에 전송 자의
				연락처를 명시하도록 조치합니다.</li>
		</ol>
		<h4>11. 권익침해 구제방법</h4>
		<p>이용자는 아래의 기관에서 개인정보침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.</p>
		<div class="table-wrap">
			<table>
				<caption>개인정보침해신고센터, 개인정보분쟁조정위원회, 경찰청 사이버안전국, 대검찰청 사이버수사과 항목으로 이루어진 테이블입니다.</caption>
				<colgroup>
					<col width="auto">
					<col width="auto">
					<col width="auto">
					<col width="auto">
				</colgroup>
				<thead>
					<tr>
						<th scope="col">개인정보 침해신고센터</th>
						<th scope="col">개인정보분쟁조정위원회</th>
						<th scope="col">경찰청 사이버안전국</th>
						<th scope="col">대검찰청 사이버수사과</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<a href="mailto:privacy.kisa.or.kr">privacy.kisa.or.kr</a>
							<br>
							(국번없이 118)
						</td>
						<td>
							<a href="mailto:www.kopico.go.kr">www.kopico.go.kr</a>
							<br>
							(국번없이 1833-6972)
						</td>
						<td>
							<a href="mailto:ecrm.cyber.go.kr/minwon/main">ecrm.cyber.go.kr/minwon/main</a>
							<br>
							(국번없이 182)
						</td>
						<td>
							<a href="mailto:spo.go.kr">spo.go.kr</a>
							<br>
							(국번없이 1301)
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<h4>12. 개인정보 보호책임자에 관한 사항</h4>
		<ol>
			<li>① KB 오케어는 이용자가 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 이용자에게 고지한 사항들에 반하는 사고가 발생할 시에 그 책임은 KB
				오케어는 있습니다.</li>
			<li>② 그러나 기술적인 보완 조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물 내용에 의한 각종 분쟁에
				관해서는 KB 오케어는 책임을 지지 않습니다.</li>
			<li>③ 이용자의 개인정보를 보호하는 책임자는 다음과 같으며 개인정보 관련 문의사항에 성실하게 답변해드리고 있습니다.
			<div class="table-wrap">
				<table>
					<caption>개인정보보호 책임자, 개인정보보호 담당자 항목으로 이루어진 테이블입니다.</caption>
					<colgroup>
						<col widht="auto">
						<col widht="auto">
						<col widht="auto">
						<col widht="auto">
						<col widht="auto">
					</colgroup>
					<thead>
						<tr>
							<th scope="col">구분</th>
							<th scope="col">부서</th>
							<th scope="col">성명</th>
							<th scope="col">전자우편</th>
							<th scope="col">연락처</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th scope="row">개인정보 보호책임자</th>
							<td rowspan="2">기획실 정보보호유닛</td>
							<td>최범진</td>
							<td rowspan="2"><a href="mailto:privacy@kbhc.co.kr">privacy@kbhc.co.kr</a></td>
							<td rowspan="2">02-2135-5062</td>
						</tr>
						<tr>
							<th scope="row">개인정보 보호담당자</th>
							<td rowspan="2">조준희</td>
						</tr>
					</tbody>
				</table>
			</div>
			</li>
		</ol>
		<h4>13. 개인정보처리방침의 변경</h4>
		<ol>
			<li>① 공고 일자 : 2022년 12월 27일</li>
			<li>② 시행 일자 : 2022년 12월 27일</li>
		</ol>
	</div>
</template>

<script>
export default {
	name: 'PersonV14',
}
</script>