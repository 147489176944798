<template>
	<article class="terms-area">
		<h1>개인정보처리방침</h1>
		<h2>총칙</h2>
		<p>㈜케이비헬스케어의 KB 오케어X서비스(이하 “KB 오케어X”라 함)는 회원의 정보를 소중하게 생각하며 권리보호를 위해 , 「개인정보보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보보호법」 제30조에 따라 회원에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립&middot;공개합니다. </p>

		<section class="mt-x4">
			<h2>주요 개인정보 처리 표시(라벨링)</h2>
			<div class="terms-labeling">
				<div class="terms-labeling-item">
					<h3>개인정보 수집</h3>
					<p>
						<span class="privacy-label" data-category="1"><span class="a11y-hidden">자동화 수집</span></span>
					</p>
					<p>이름, 생년월일, 성별, 휴대폰번호, CI값 등</p>
				</div>
				<div class="terms-labeling-item">
					<h3>개인정보 처리목적</h3>
					<p>
						<span class="privacy-label" data-process="2"><span class="a11y-hidden">처리목적</span></span>
					</p>
					<p>회원식별 및 회원제서비스제공</p>
				</div>
				<div class="terms-labeling-item">
					<h3>개인정보 보유기간</h3>
					<p>
						<span class="privacy-label" data-process="10"><span class="a11y-hidden">보유기간(설정)</span></span>
					</p>
					<p>회원탈퇴시 또는 법정의무보유기간만료시</p>
				</div>
				<div class="terms-labeling-item">
					<h3>개인정보 제3자 제공</h3>
					<p>
						<span class="privacy-label" data-process="9"><span class="a11y-hidden">제3자 제공</span></span>
					</p>
					<p>-</p>
				</div>
				<div class="terms-labeling-item">
					<h3>개인정보처리위탁</h3>
					<p>
						<span class="privacy-label" data-process="8"><span class="a11y-hidden">처리위탁</span></span>
					</p>
					<p>KCB, 메가존클라우드 등</p>
				</div>
				<div class="terms-labeling-item">
					<h3>개인정보 민원처리 담당부서</h3>
					<p>
						<span class="privacy-label" data-requirement="9"><span class="a11y-hidden">고충처리부서</span></span>
					</p> 
					<p>이메일: privacy@kbhc.co.kr</p>
					<p>전화: 02-2135-5062</p>
				</div>
			</div>
		</section>
		<section class="mt-x4">
			<h2>목차</h2>
			<p>KB 오케어X는 회원의 개인정보를 보호하기 위하여 아래와 같이 개인정보처리방침을 가지고 있습니다.</p>
		</section>
		<hr>
		<section>
			<input type="checkbox" title="열기" id="acc1" class="a11y-hidden">
			<div class="acco-tit">
				<h3>1. 개인정보의 처리목적, 수집항목, 보유 및 이용기간</h3>
				<div class="privacy-label-wrap">
					<span class="privacy-label" data-category="1"><span class="a11y-hidden">자동화 수집</span></span>
					<span class="privacy-label" data-process="2"><span class="a11y-hidden">처리목적</span></span>
					<span class="privacy-label" data-process="1"><span class="a11y-hidden">처리항목</span></span>
					<span class="privacy-label" data-process="10"><span class="a11y-hidden">보유기간</span></span>
				</div>
				<label data-acco="acc1" for="acc1"><span class="a11y-hidden">열기</span></label>
			</div> 
			<div class="acco-pnl" data-acco="acc1">
				<ol class="list-base" data-number="base" data-shape="round">
					<li>
						<div class="group">
							<p>KB 오케어X에서 처리한 개인정보는 다음의 목적 이외의 용도로 사용되지 않으며 이용 목적이 변경되는 경우에도 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행합니다.</p>
							<div class="tbl-wrap">
								<table class="c-c-l-c">
									<caption>'서비스, 수집 &middot; 이용 목적, 개인정보 수집 &middot; 이용 항목, 보유 및 이용기간' 정보 테이블</caption>
									<colgroup>
										<col style="width:10%">
										<col style="width:25%">
										<col style="width:50%">
										<col style="width:15%">
									</colgroup>
									<thead>
										<tr>
											<th scope="col">서비스</th>
											<th scope="col">수집 &middot; 이용 목적</th>
											<th scope="col">수집 &middot; 이용 항목</th>
											<th scope="col">보유 및 이용기간</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th rowspan="6" scope="rowgroup">회원관리</th>
											<th scope="row">(카카오싱크) 간편 로그인 및 회원가입</th>
											<td>
												<p>[필수] 프로필정보(닉네임/프로필사진) , 카카오계정(이메일 또는 휴대폰번호), 생년월일, 이름, CI</p>
												<p>[선택] 없음</p>
											</td>
											<td>본인인증 미확인시 2년 후 파기<br>*CI(중복가입여부 확인후 즉시파기)</td>
										</tr>
										<tr>
											<th scope="row">회원제 서비스 이용을 위한<br> 본인인증</th>
											<td>
												<p>[필수] 이름, 생년월일, 성별, 통신사, 휴대폰번호, CI, 내/외국인여부</p>
												<p>[선택] 없음</p>
											</td>
											<td class="tc">본인확인즉시 파기</td>
										</tr>
										<tr>
											<th scope="row">회원가입 및 관리<br>(공지, 안내 등)</th>
											<td>
												<p>[필수] 이름, 생년월일, 성별, 휴대폰번호, CI, 카카오계정, 카카오톡채널 추가 상태 및 내역, 프로필정보, 통신사, 내/외국인여부</p>
												<p>[선택] 없음</p>
											</td>
											<td class="tc">회원 탈퇴 시 파기</td>
										</tr>
										<tr>
											<th scope="row">불량회원의 부정이용 방지와 비인가 사용 방지, 중복 가입방지</th>
											<td>
												<p>[필수] CI</p>
												<p>[선택] 없음</p>
											</td>
											<td class="tc">탈퇴 1년 후 파기</td>
										</tr>
										<tr>
											<th scope="row">고객상담, 고객불만 접수 및 처리, 분쟁조정을 위한 기록보존</th>
											<td>
												<p>[필수] 이름, 휴대폰번호, 이메일, 거래 또는 이용내역, 처리결과</p>
												<p>[선택] 없음</p>
											</td>
											<td class="tc">문의 또는 상담<br>완료3년후 파기</td>
										</tr>
										<tr>
											<th scope="row">애플 로그인 연동</th>
											<td>
												<p>[필수] 인증키값</p>
											</td>
											<td rowspan="7" class="tc">회원 탈퇴 시 파기</td>
										</tr>
										<tr>
											<th rowspan="8" scope="rowgroup">서비스제공</th>
											<th scope="row">라이프 로그<br>(건강관리 및 건강기록 서비스)</th>
											<td>
												<p>[필수] 이름, 나이, 성별, 걸음 및 운동 정보(걸음수, 이동속도, 이동거리, 운동, 소모칼로리), 건강정보(체성분, 수면, 수분, 수축기혈압, 이완기혈압, 혈당, 공복혈당, 심박수, 영양제/약 복약(종류, 약복용기간 및 시간), 식사, 감정, 흡연, 음주 관련 정보), 여성건강 정보(월경주기, 월경 시작일/종료일, 월경통 정보, 몸 상태 정보)</p>
												<p>[선택] 없음</p>
											</td>
										</tr>
										<tr>
											<th scope="row">(전문가/일반)챌린지 서비스</th>
											<td>
												<p>[필수] 이름, 나이, 성별, 라이프로그 기록정보(걸음수, 수면, 운동, 혈당, 혈압, 체중, 수분, 식사), 챌린지 도전 결과</p>
												<p>[선택] 없음</p>
											</td>
										</tr>
										<tr>
											<th scope="row">건강체크 설문결과 분석서비스</th>
											<td>
												<p>[필수] 이름, 나이, 성별, 키, 몸무게, 혈압수치, 혈당수치, 시력, 보유질환, 복용약, 가족 질병여부, 음주주량, 흡연여부</p>
												<p>[선택] 없음</p>
											</td>
										</tr>
										<tr>
											<th scope="row">심리검사 결과 분석 서비스</th>
											<td>
												<p>[필수] 이름, 심리검사(마음건강, 성격유형, 성격특질) 설문결과 및 분석결과</p>
												<p>[선택] 없음</p>
											</td>
										</tr>
										<tr>
											<th scope="row">(일반검진)건강검진결과 조회 및 검진결과 기반 건강정보/증진서비스 제공</th>
											<td>
												<p>[필수] 이름, 성별, 나이, 검진기관명, 검진일, 키, 몸무게, 허리둘레, 흉부질환검사결과, 혈압, 비만여부, 혈색소, 공복혈당, 이상지질혈증 검사 결과(총콜레스테롤, HDL콜레스테롤, 중성지방, LDL콜레스테롤), 간장질환검사결과(AST,ALT, γ-GTP), 신장질환검사결과(요단백, 혈청크레아닌, 신사구체여과율), 시력/청력 검사 결과, 생활습관 평가 결과<br>*정보주체별 수집하는 정보가 상이 할 수 있음</p>
												<p>[선택] 없음</p>
											</td>
										</tr>
										<tr>
											<th scope="row">증상 유사도 분석 서비스</th>
											<td>
												<p>[필수] 이름, 성별, 증상 유사도 분석 결과</p>
												<p>[선택] 없음</p>
											</td>
										</tr>
										<tr>
											<th scope="row">1:1문의에 대한 답변 및 문의내역 관리 및 조회</th>
											<td>
												<p>[필수] 이름, 휴대폰번호, 문의유형, 문의내용, 문의결과 답변</p>
												<p>[선택] 없음</p>
											</td>
											<td class="tc">문의 또는 상담<br>완료3년후 파기</td>
										</tr>
										<tr>
											<th scope="row">서비스 홍보 및<br>광고성 정보 제공</th>
											<td>
												<p>[필수] 없음</p>
												<p>[선택] 이름, 이메일, 휴대폰번호</p>
											</td>
											<td class="tc">회원탈퇴 시 또는<br>동의철회시 파기</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</li>
					<li>
						개인정보 수집 방법
						<div class="group">
							<p>- 모바일APP(회원가입, 경품응모, 배송요청 등)에서 수집</p>
							<p>- 서비스 이용과정 자동 생성되는 정보</p>
						</div>
					</li>
					<li>KB 오케어X는 만 14세 이상의 이용자에 한해서 서비스(회원가입 등)를 제공하는 것을 원칙으로 합니다.</li>
					<li>
						<div class="group">
							<p>관련 법령에 의한 개인정보 보관</p>
							<div class="tbl-wrap">
								<table>
									<caption>'수집항목, 이용목적, 근거법률, 보유기간' 정보 테이블</caption>
									<colgroup>
										<col style="width:30%">
										<col style="width:40%">
										<col style="width:15%">
										<col style="width:15%">
									</colgroup>
									<thead>
										<tr>
											<th scope="col">수집항목</th>
											<th scope="col">이용목적</th>
											<th scope="col">근거법률</th>
											<th scope="col">보유기간</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th scope="row" class="tc">접속일, 접속IP, 접속기기</th>
											<td>수사기관제공(법원 영장 등 정당한 절차의 경우에 제공)</td>
											<td class="tc">통신비밀보호법</td>
											<td class="tc">3개월</td>
										</tr>
										<tr>
											<th scope="row" class="tc">회원식별정보, 분쟁처리기록, 계약 철회기록</th>
											<td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
											<td rowspan="2" class="tc">전자상거래법</td>
											<td class="tc">3년</td>
										</tr>
										<tr>
											<th scope="row" class="tc">계약 및 철회 기록, 대금결제 기록</th>
											<td>대금 결제 재화 등의 공급에 관한 기록<br>계약 또는 철회 등에 관한 기록</td>
											<td class="tc">5년</td>
										</tr>
										<tr>
											<th scope="row" class="tc">위도, 경도 좌표</th>
											<td>위치정보취급대장</td>
											<td class="tc">위치정보보호법</td>
											<td class="tc">6개월</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div>
								<p>※ 소득세법에 따라 경품 수령을 위한 제세공과금 처리 목적으로 회원의 주민등록번호, 계좌번호 및 주소 정보를 수집할 수 있습니다.(시행규칙 제100조 제25호)</p>
							</div>
						</div>
					</li>
					<li>
						서비스 이용과정에서 자동으로 생성되는 정보
						<div class="tbl-wrap">
							<table>
								<caption>'수집 및 이용목적, 수집 항목, 보유기간' 정보 테이블</caption>
								<colgroup>
									<col style="width:20%">
									<col style="width:50%">
									<col style="width:30%">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">수집 및 이용목적</th>
										<th scope="col">수집 항목</th>
										<th scope="col">보유기간</th>
									</tr>
								</thead>
								<tbody class="tc">
									<tr>
										<th scope="row">서비스 이용 통계</th>
										<td>모바일 기기정보( OS종류 및 버전, 디바이스 종류, 접속IP)<br>* 앱이용시 추가 모바일 기기정보 : UUID(임의 생성한 앱 고유값)</td>
										<td>회원 탈퇴 시 또는 법정 의무 보유기간<br>※앱 이용 시 수집 정보는 앱 삭제 시 동시에 삭제</td>
									</tr>
								</tbody>
							</table>
						</div>
					</li>
					<li>
						모바일 앱 서비스를 위하여 아래와 같이 이용자의 이동통신단말기 내 정보 및 기능에 접근, 이용할 수 있습니다. 모바일 앱 서비스에서 요청하는 모든 요청권한은 선택적 접근 권한으로 선택 접근항목은 해당 서비스 최초 이용 시 별도 동의를 받습니다. 선택 접근항목의 경우 OS버전에 따라 동의 받는 방법이 다를 수 있으나, 회원이 거부하더라도 기본 서비스 이용에는 제한이 없습니다.
						<div class="tbl-wrap">
							<table>
								<caption>'구분, 접근권한, 접근항목, 이용목적, 비고' 정보 테이블</caption>
								<colgroup>
									<col style="width:15%">
									<col style="width:15%">
									<col style="width:15%">
									<col style="width:40%">
									<col style="width:15%">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">구분</th>
										<th scope="col">접근권한</th>
										<th scope="col">접근항목</th>
										<th scope="col">이용목적</th>
										<th scope="col">비고</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="tc">AOS/iOS</td>
										<td class="tc">선택</td>
										<td class="tc">파일 및 미디어<br>(사진 및 동영상)</td>
										<td>라이프로그 건강기록(식단기록), 챌리지 인증, 1:1문의</td>
										<td rowspan="8" class="tc">※ 선택적 접근권한은 동의하지 않아도 기본 서비스 이용이 가능</td>
									</tr>
									<tr>
										<td class="tc">AOS/iOS</td>
										<td class="tc">선택</td>
										<td class="tc">근처기기(블루투스)</td>
										<td>라이프로그 건강기록 장비연동 (체중계, 혈압계, 혈당계 등)</td>
									</tr>
									<tr>
										<td class="tc">AOS/iOS</td>
										<td class="tc">선택</td>
										<td class="tc">위치</td>
										<td>병원 및 약국찾기</td>
									</tr>
									<tr>
										<td class="tc">AOS/iOS</td>
										<td class="tc">선택</td>
										<td class="tc">카메라</td>
										<td>라이프로그 건강기록(식단기록), 챌린지 인증, 1:1문의</td>
									</tr>
									<tr>
										<td class="tc">AOS/iOS</td>
										<td class="tc">선택</td>
										<td class="tc">건강정보/신체활동</td>
										<td>라이프로그 건강기록(플랫폼 건강정보연동)</td>
									</tr>
									<tr>
										<td class="tc">AOS/iOS</td>
										<td class="tc">선택</td>
										<td class="tc">알림</td>
										<td>건강뉴스, 복약알림 서비스 등 이용시 알림 수신</td>
									</tr>
									<tr>
										<td class="tc">iOS</td>
										<td class="tc">선택</td>
										<td class="tc">페이스ID/지문</td>
										<td>사용자 생체인증</td>
									</tr>
									<tr>
										<td class="tc">AOS</td>
										<td class="tc">필수</td>
										<td class="tc">전화</td>
										<td>단말기정보 및 네트워크 확인</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div>
							<p>※ KB 오케어X 서비스 앱 접근권한 동의 또는 철회 방법</p>
							<div class="group">
								<p>- 안드로이드 계열 : 설정 → 애플리케이션 → ‘KB 오케어X’ 앱 선택 → 권한 → 접근 항목별 ‘허용’ 또는 ‘허용 안함’ 체크</p>
								<p>- iOS계열 : 설정 → ‘KB 오케어X’ 앱 선택 → 접근권한 별 동의 또는 철회 체크</p>
							</div>
						</div>
					</li>
				</ol>
			</div>
		</section>
		<hr>
		<section>
			<input type="checkbox" title="열기" id="acc2" class="a11y-hidden">
			<div class="acco-tit">
				<h3>2. 개인정보의 제3자제공</h3>
				<div class="privacy-label-wrap">
					<span class="privacy-label" data-process="9"><span class="a11y-hidden">개인정보의 3자제공</span></span>
					<span class="privacy-label" data-process="7"><span class="a11y-hidden">국외이전</span></span>
				</div>
				<label data-acco="acc2" for="acc2"><span class="a11y-hidden">열기</span></label>
			</div>
			<div class="acco-pnl" data-acco="acc2">
				<ol class="list-base" data-number="base" data-shape="round">
					<li>
						KB 오케어X는 정보주체의 동의, 법률의 특별한 규정 등 개인정보보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
						<div class="group">
							<p>- 이용자가 서비스 이용중 제3자 제공에 동의한 경우</p>
						</div>
					</li>
				</ol>
			</div>
		</section>
		<hr>
		<section>
			<input type="checkbox" title="열기" id="acc3" class="a11y-hidden">
			<div class="acco-tit">
				<h3>3. 개인정보의 처리위탁</h3>
				<div class="privacy-label-wrap">
					<span class="privacy-label" data-process="8"><span class="a11y-hidden">처리위탁</span></span>
				</div>
				<label data-acco="acc3" for="acc3"><span class="a11y-hidden">열기</span></label>
			</div>
			<div class="acco-pnl" data-acco="acc3">
				<ol class="list-base" data-number="base" data-shape="round">
					<li>KB 오케어X는 서비스 향상을 위하여 개인정보를 제한된 범위에서 업체에 위탁하여 관리하도록 할 수 있습니다.</li>
					<li>
						KB 오케어X는 관계 법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다. 또한, 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
						<div class="tbl-wrap">
							<table>
								<caption>'수탁자, 서비스 내용, 보유 및 이용기간' 정보 테이블</caption>
								<colgroup>
									<col style="width:25%;">
									<col style="width: auto;">
									<col style="width: auto;">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">수탁자</th>
										<th scope="col">서비스 내용</th>
										<th scope="col">보유 및 이용기간</th>
									</tr>
								</thead>
								<tbody class="tc">
									<tr>
										<th scope="row">KB 데이터시스템</th>
										<td>클라우드 운영(AZURE)</td>
										<td>위탁 계약 종료시</td>
									</tr>
									<tr>
										<th scope="row">KB 국민은행</th>
										<td>본인인증 및 전자서명 서비스</td>
										<td>위탁 계약 종료시</td>
									</tr>
									<tr>
										<th scope="row">코리아크레딧뷰로㈜</th>
										<td>휴대폰 본인확인서비스</td>
										<td>위탁 계약 종료시</td>
									</tr>
								</tbody>
							</table>
						</div>
					</li>
				</ol>
			</div> 
		</section>
		<hr>

		<section>
			<input type="checkbox" title="열기" id="acc4" class="a11y-hidden">
			<div class="acco-tit">
				<h3>4. 개인위치정보의 처리</h3>
				<div class="privacy-label-wrap">
					<span class="privacy-label" data-category="5"><span class="a11y-hidden">위치</span></span>
				</div>
				<label data-acco="acc4" for="acc4"><span class="a11y-hidden">열기</span></label>
			</div>
			<div class="acco-pnl" data-acco="acc4">
				<ol class="list-base" data-number="base" data-shape="round">
					<li>KB 오케어X는 위치정보의 보호 및 이용 동에 관한 법률(이하 "위치정보법")에 따라 다음과 같이 개인위치정보를 처리합니다.</li>
					<li>
						개인위치정보의 처리목적 및 보유기간
						<div class="tbl-wrap">
							<table>
								<caption>'서비스명, 처리목적, 보유기간' 정보 테이블</caption>
								<colgroup>
									<col style="width:25%;">
									<col style="width: auto;">
									<col style="width: auto;">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">서비스명</th>
										<th scope="col">처리목적</th>
										<th scope="col">보유기간</th>
									</tr>
								</thead>
								<tbody class="tc">
									<tr>
										<th scope="row">약국 및 병원찾기</th>
										<td>위치정보수집 대상의 실시간 위치확인<br>이용자의 위치에서 근접한 약국 및 병원 정보 제공</td>
										<td>일회성 또는 임시로 이용 후 지체없이 파기</td>
									</tr>
									<tr>
										<th scope="row">건강 날씨</th>
										<td>위치정보수집 대상의 실시간 위치확인<br>이용자의 위치에 기반하여 날씨에 기반한 메시지 제공</td>
										<td>일회성 또는 임시로 이용 후 지체없이 파기</td>
									</tr>
									<tr>
										<th scope="row">질환주의보</th>
										<td>위치정보수집 대상의 실시간 위치확인<br>이용자의 위치에 기반하여 국민건강보험공단 질병예측 정보에 기반한 메시지 제공</td>
										<td>일회성 또는 임시로 이용 후 지체없이 파기</td>
									</tr>
								</tbody>
							</table>
						</div>
					</li>
					<li>
						개인위치정보 수집&middot;이용&middot;제공 사실 확인자료의 보유근거 및 보유기간<br>KB 오케어X는 위치정보법 제16조2항에 근거하여 이용자의 위치정보 수집&middot;이용&middot;제공사실 확인자료를 위치정보 시스템에 자동으로 기록하며, 6개월간 보관합니다.
					</li>
					<li>
						개인위치정보의 파기 절차 및 방법
						<p>- 종이에 출력된 개인 정보 : 분쇄기로 분쇄하거나 소각</p>
						<p>- 전자적 파일 형태로 저장된 개인 정보 : 기록을 재사용할 수 없는 기술적 방법을 사용하여 삭제</p>
					</li>
					<li>
						개인위치정보의 제3자 제공 및 통보에 관한 사항<br>
						KB 오케어X는 이용자의 사전 동의없이 개인위치정보를 제3자에게 제공하지 않고 있습니다. 단, 이용자가 지정한 제3자에게 제공하는 경우에는 매회 이용자에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다.
					</li>
					<li>
						법 제25조 제1항에 따른 보호의무자의 권리
						<p>
							- KB 오케어X는 다음에 해당하는 자의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.<br>
							&middot; 8세 이하의 아동<br>
							&middot; 피성년후견인<br>
							&middot; 장애인복지법 제2조 제2항 제2호에 따른 정신적 장애를 가진 자로서 장애인고용촉진 및 직업재활법 제2조제2호에 따른 중증장애인에 해당하는 자(장애인복지법 제32조에 따라 장애인 등록을 한 자만 해당)
						</p>
						<p>- 8세 이하의 아동의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 하며, 이 경우 보호의무자는 개인위치정보 주체의 권리를 모두 가집니다.</p>
					</li>
					<li>
						위치정보관리책임자의 정보
						<div class="tbl-wrap">
							<table>
								<caption>'구분, 부서, 이름, 전자우편, 연락처' 정보 테이블</caption>
								<colgroup>
									<col style="width:20%;">
									<col style="width:20%;">
									<col style="width:20%;">
									<col style="width:20%;">
									<col style="width:20%;">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">구분</th>
										<th scope="col">부서</th>
										<th scope="col">이름</th>
										<th scope="col">전자우편</th>
										<th scope="col">연락처</th>
									</tr>
								</thead>
								<tbody class="tc">
									<tr>
										<td>위치정보관리책임자</td>
										<td>사업전략본부</td>
										<td>민경필</td>
										<td>privacy@kbhc.co.kr</td>
										<td>02-2135-5062</td>
									</tr>
								</tbody>
							</table>
						</div>
					</li>
				</ol>
			</div>           
		</section>
		<hr>
		<section>
			<input type="checkbox" title="열기" id="acc5" class="a11y-hidden">
			<div class="acco-tit">
				<h3>5. 개인정보의 파기 절차 및 방법</h3>
				<div class="privacy-label-wrap">
					<span class="privacy-label" data-process="11"><span class="a11y-hidden">파기</span></span>
				</div>
				<label data-acco="acc5" for="acc5"><span class="a11y-hidden">열기</span></label>
			</div>
			<div class="acco-pnl" data-acco="acc5">
				<ol class="list-base" data-number="base" data-shape="round">
					<li>KB 오케어X는 개인정보보호책임자의 책임 하에 개인정보 보유기간의 경과, 처리목적 달성, 회원탈퇴 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</li>
					<li>다만, 본인확인값(CI)은 최소한의 식별을 위한 개인정보이므로 서비스 이용혼란 및 부정사용 방지를 위해 회원탈퇴 이후 1년간 보유할 수 있습니다.</li>
					<li>정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령 또는 내부 방침에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 외부와 차단된 별도 DB 또는 테이블에 분리하여 보관합니다.</li>
					<li>
						개인정보 파기의 방법은 다음과 같습니다.<br>
						<div class="group">
							<p>&middot; 전자적 파일 형태로 기록&middot;저장된 개인정보 : 기록을 재생할 수 없도록 파기</p>
							<p>&middot; 종이 문서에 기록&middot;저장된 개인정보 : 분쇄기로 분쇄하거나 소각하여 파기</p>
						</div>
					</li>
				</ol>
			</div>           
		</section>
		<hr>
		<section>
			<input type="checkbox" title="열기" id="acc6" class="a11y-hidden">
			<div class="acco-tit">
				<h3>6. 이용자의 권리&middot;의무 및 행사방법</h3>
				<div class="privacy-label-wrap">
					<span class="privacy-label" data-requirement="1"><span class="a11y-hidden">권리의무</span></span>
					<span class="privacy-label" data-requirement="11"><span class="a11y-hidden">열람청구</span></span>
				</div>
				<label data-acco="acc6" for="acc6"><span class="a11y-hidden">열기</span></label>
			</div>
			<div class="acco-pnl" data-acco="acc6">
				<ol class="list-base" data-number="base" data-shape="round">
					<li>이용자는 개인정보 보호법 제35조에 따른 개인정보의 열람&middot;정정&middot;처리정지&middot;삭제 청구를 이메일, 전화, 방문 등의 방법으로 아래의 부서에 할 수 있습니다. 회사는 이용자의 개인정보 열람&middot;정정&middot;처리정지&middot;삭제 청구가 신속하게 처리되도록 노력하겠습니다.
						<div class="tbl-wrap">
						<table>
							<caption>'개인정보 민원처리 담당부서' 정보 테이블</caption>
							<thead>
								<tr>
								<th scope="col">개인정보 민원처리 담당부서</th>
								</tr>
							</thead>
							<tbody class="tc">
								<tr>
									<td>인프라/정보보호 파트</td>
								</tr>
								<tr>
									<td>이메일: privacy@kbhc.co.kr</td>
								</tr>
								<tr>
									<td>전화: 02-2135-5062</td>
								</tr>
							</tbody>
						</table>
						</div>
						다만, 근무 시간 이후 또는 주말 및 공휴일에는 익일 처리하는 것을 원칙으로 합니다.
					</li>
					<li>권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 처리방법에 관한 고시(2020-7호) 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</li>
					<li>
						이용자의 권리에 따른 열람의 요구, 정정&middot;삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한자가 본인이거나 정당한 대리인인지를 확인합니다.
						<div>
							<p>[개인정보 처리방법에 관한 고시(2020-7호) 별지 제8호] 개인정보 열람 요구서</p>
							<p>[개인정보 처리방법에 관한 고시(2020-7호) 별지 제11호] 위임장</p>
						</div>
					</li>
					<li>개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 이용자의 권리가 제한될 수 있습니다.</li>
					<li>이용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위 정보를 입력할 경우 회원 자격이 상실될 수 있습니다.</li>
					<li>이용자는 언제든지 『마이페이지』 메뉴를 통해 자신의 개인정보를 조회하거나 수정할 수 있습니다.</li>
				</ol>
			</div>        
		</section>
		<hr>
		<section>
			<input type="checkbox" title="열기" id="acc7" class="a11y-hidden">
			<div class="acco-tit">
				<h3>7. 개인정보의 자동 수집장치의 설치&middot;운영 및 거부에 관한 사항</h3>
				<div class="privacy-label-wrap">
					<span class="privacy-label" data-process="4"><span class="a11y-hidden">자동화 수집</span></span>
				</div>
				<label data-acco="acc7" for="acc7"><span class="a11y-hidden">열기</span></label>
			</div>
			<div class="acco-pnl" data-acco="acc7">
				<p>KB 오케어X는 서비스 이용과정에서 이용자로부터 다음과 같은 정보들이 자동으로 생성&middot;수집되고 다음의 목적으로 이용될 수 있습니다.</p>
				<div class="tbl-wrap">
					<table>
						<caption>'개인정보 자동수집정보 사용목적' 정보 테이블</caption>
						<thead>
							<tr>
								<th scope="col">개인정보 자동수집정보 사용목적</th>
							</tr>
						</thead>
						<tbody>
							<tr class="tc">
								<td>
									KB 오케어X는 관련법규의 준수를 위해 이용자의 접속기록(로그인)을 보관할 의무가 있습니다.<br>KB 오케어X는 이용자의 이용기록을 분석하여 서비스 개선
								</td>
							</tr>
						</tbody>        
					</table>
				</div>
				<div class="tbl-wrap">
					<table>
						<caption>'개인정보 자동수집안내' 정보 테이블</caption>
						<thead>
							<tr>
								<th scope="col">개인정보 자동수집안내</th>
							</tr>
						</thead>
						<tbody>
							<tr class="tc">
								<td>
									앱을 통해 서비스를 실행 시 기기식별 고유번호(예: 장치 ID 등), 단말기 정보를 자동으로 수집하게 됩니다.<br> 
									이용자가 기기식별 고유번호를 자동으로 수집하는 것을 거부하는 경우 회사에서 제공한 서비스를 이용하실 수 없습니다.
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="tbl-wrap">
					<table>
						<caption>'개인정보 자동수집정보 사용목적' 정보 테이블</caption>
						<thead>
							<tr>
								<th scope="col">개인정보 자동수집 거부방법(예시)</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									&middot; Internet Explorer의 경우 : 웹 브라우저 상단의 도구 메뉴 > 인터넷 옵션 > 개인정보 > 설정<br>
									&middot; Chrome의 경우 : 웹 브라우저 우측의 설정 메뉴 > 화면 하단의 고급 설정 표시 > 개인정보의 콘텐츠 설정 버튼 > 쿠키<br>
									&middot; Edge의 경우 : 설정 및 기타 메뉴 > 설정 > 쿠키 및 사이트 권한 > 쿠키 및 사이트 데이터<br>
									&middot; 안드로이드 폰 : 휴대폰 설정 > 앱 관리> KB 오케어X > 저장소 > 사용자데이터/캐시 > 데이터 지우기<br>
									&middot; 아이폰 : 설정 > 앱 관리 > KB 오케어X > 저장소 > 데이터 지우기
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>    
		</section>
		<hr>
		<section>
			<input type="checkbox" title="열기" id="acc8" class="a11y-hidden">
			<div class="acco-tit">
				<h3>8. 행태정보의 수집&middot;이용&middot;제공&middot;거부사항</h3>
				<div class="privacy-label-wrap">
					<span class="privacy-label" data-process="5"><span class="a11y-hidden">자동화 수집</span></span>
				</div>
				<label data-acco="acc8" for="acc8"><span class="a11y-hidden">열기</span></label>
			</div>
			<div class="acco-pnl" data-acco="acc8">
				<ol class="list-base" data-number="base" data-shape="round">
					<li>KB 오케어X는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기 위하여 행태정보를 수집&middot;이용하고 있습니다.</li>
					<li>
						수집 및 이용하는 행태정보
						<div class="tbl-wrap">
							<table>
								<caption>'수집하는 행태정보의 항목, 행태정보 수집 방법, 행태정보 수집 목적, 보유 및 이용기간' 정보 테이블</caption>
								<colgroup>
									<col style="width:25%;">
									<col style="width:25%;">
									<col style="width:25%;">
									<col style="width:25%;">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">수집하는 행태정보의 항목</th>
										<th scope="col">행태정보 수집 방법</th>
										<th scope="col">행태정보 수집 목적</th>
										<th scope="col">보유 및 이용기간</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>앱 서비스 방문기록, 검색/결제/구매 등 사용 이력</td>
										<td>이용자가 앱 내에서 행해지는 주요행동에 대해 생성 정보 수집 툴(에어브릿지, Hackle)을 통해 자동 수집</td>
										<td>
											- 이용자의 관심, 성향에 기반한 개인 맞춤형 상품추천서비스(광고포함)를 제공<br>
											- 이용자 행태정보 분석하여 신상품 개발, 서비스 개편 등 척도로 활용
										</td>
										<td>
											행태정보는 수집일로부터 2년간 보유 <br>보유기간 경과 후에는 비식별화 조치 후 통계 업무 목적만을 위하여 보유
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="tbl-wrap">
							<table>
								<caption>'이용자 통제권 행사방법, 이용자 피해구제 방법' 정보 테이블</caption>
								<colgroup>
									<col style="width:50%;">
									<col style="width:50%;">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">이용자 통제권 행사방법</th>
										<th scope="col">이용자 피해구제 방법</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											Android: 홈 > 설정 > Google > 광고 > 광고 맞춤설정 선택 해제 (ON)<br>
											iPhone: 홈 > 설정 > 개인 정보 보호 > 추적 > 앱이 추적을 요청하도록 허용 (OFF)<br>
											※ 모바일 OS 버전에 따라 메뉴 및 방법이 다소 상이할 수 있습니다.
										</td>
										<td>
											맞춤형 광고에 대한 문의 또는 불만 사항 등이 있으실 경우, 아래로 연락 주시면 적극적으로 대응 및 회신하겠습니다.<br>
											KB헬스케어 고객센터(전화 : 1544-3677)
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</li>
					<li>
						제공하는 행태정보
						<div class="tbl-wrap">
							<table>
								<caption>'형태정보 수집 및 처리 광고사업자, 수집 및 처리되는 형태정보의 항목, 행태정보 수집 및 방법, 행태정보 수집 목적' 정보 테이블</caption>
								<colgroup>
									<col style="width:25%;">
									<col style="width:25%;">
									<col style="width:25%;">
									<col style="width:25%;">
								</colgroup>
								<thead>
									<tr>
										<th scope="col">행태정보 수집 및 처리<br>광고사업자</th>
										<th scope="col">수집 및 처리되는<br>행태정보의 항목</th>
										<th scope="col">행태정보 수집 및 방법</th>
										<th scope="col">행태정보 수집 목적</th>
									</tr>
								</thead>
								<tbody class="tc">
									<tr>
										<td>AB180</td>
										<td>이용자의 서비스 방문기록, 활동로그 및 검색이력</td>
										<td>이용자의 서비스 이용시 자동 수집 및 전송</td>
										<td>이용자의 관심, 흥미, 기호 및 성향기반 맞춤형 광고 제공</td>
									</tr>
								</tbody>
							</table>
						</div>
					</li>
				</ol>
			</div>    
		</section>
		<hr>
		<section>
			<input type="checkbox" title="열기" id="acc9" class="a11y-hidden">
			<div class="acco-tit">
				<h3>9. 개인정보의 안전성 확보조치</h3>
				<div class="privacy-label-wrap">
					<span class="privacy-label" data-requirement="2"><span class="a11y-hidden">안정성확보조치</span></span>
				</div>
				<label data-acco="acc9" for="acc9"><span class="a11y-hidden">열기</span></label>
			</div>
			<div class="acco-pnl" data-acco="acc9">
				<p>KB 오케어X는 「개인정보보호법」 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.</p>
				<ol class="list-base" data-number="base" data-shape="round">
					<li>
						내부관리계획의 수립 및 시행<br>
						개인정보 내부관리계획 수립 및 시행은 ㈜케이비헬스케어의 내부관리 지침을 준수하여 시행합니다.
					</li>
					<li>
						개인정보 취급 담당자의 최소화 및 교육<br>
						개인정보를 취급하는 담당자를 지정하고 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.
					</li>
					<li>
						개인정보에 대한 접근 제한<br>
						개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
					</li>
					<li>
						접속기록의 보관 및 위변조 방지<br>
						개인정보처리시스템에 접속한 기록을 최소 3년 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
					</li>
					<li>
						개인정보의 암호화<br>
						이용자의 개인정보는 암호화되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.
					</li>
					<li>
						해킹 등에 대비한 기술적 대책<br>
						KB 오케어X는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신∙점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다. 또한 네트워크 트래픽의 통제(Monitoring)는 물론 불법적으로 정보를 변경하는 등의 시도를 탐지하고 있습니다.
					</li>
					<li>
						물리적 조치<br>
						전산실 및 통신실 등은 보호구역으로 설정하여 출입을 통제하고 있습니다.
					</li>
					<li>
						그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 분실, 도난, 유출, 위∙변조 또는 훼손될 경우 회사는 즉각 이용자에게 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.
					</li>
				</ol>
			</div>    
		</section>
		<hr>
		<section>
			<input type="checkbox" title="열기" id="acc10" class="a11y-hidden">
			<div class="acco-tit">
				<h3>10. 링크사이트에 대한 책임</h3>
				<label data-acco="acc10" for="acc10"><span class="a11y-hidden">열기</span></label>
			</div>
			<div class="acco-pnl" data-acco="acc10">
				<ol class="list-base" data-number="base" data-shape="round">
					<li>KB 오케어X는 회원에게 다른 회사의 웹사이트(WEB View) 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 KB 오케어X는 외부사이트와 자료에 대해 아무런 통제권이 없으므로 해당 사이트(WEB View)에서 제공받는 서비스나 자료의 진실성, 유용성 등에 대해 책임지지 않으며, 어떠한 보증도 하지 않습니다.</li>
					<li>KB 오케어X에서 포함하고 있는 링크를 클릭하여 다른 사이트(WEB View)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 회사와 무관하므로 새로 방문한 사이트의 정책을 반드시 검토하시기 바랍니다.</li>
				</ol>
			</div>
		</section>
		<hr>
		<section>
			<input type="checkbox" title="열기" id="acc11" class="a11y-hidden">
			<div class="acco-tit">
				<h3>11. 광고성 정보 전송</h3>
				<label data-acco="acc11" for="acc11"><span class="a11y-hidden">열기</span></label>
			</div>
			<div class="acco-pnl" data-acco="acc11">
				<ol class="list-base" data-number="base" data-shape="round">
					<li>KB 오케어X는 이용자의 명시적인 수신 거부 의사에 반하여 영리 목적의 광고성 정보를 전송하지 않습니다.</li>
					<li>KB 오케어X는 이용자가 뉴스레터 등 전자 우편 전송에 대한 동의를 한 경우 전자 우편의 제목란 및 본문란에 다음 사항과 같이 이용자가 쉽게 알아볼 수 있도록 조치합니다.
						<div>
							<p>- 전자 우편의 제목란: 전자 우편 본문란의 주요 내용을 표시합니다.</p>
							<p>
								- 전자 우편의 본문란<br>
								가. 이용자가 수신 거부의 의사표시를 할 수 있는 전송 자의 명칭, 전자우편주소, 전화번호 및 주소를 명시합니다.<br>
								나. 이용자가 수신 거부의 의사를 쉽게 표시할 수 있는 방법을 한글 또는 영문으로 각각 명시합니다.<br>
								다. 이용자가 동의를 한 시기 및 내용을 명시합니다.
							</p>
						</div>
					</li>
					<li>팩스, 휴대폰 문자 전송 등 전자 우편 이외의 문자 전송을 통해 영리 목적의 광고성 정보를 전송하는 경우에는 전송 내용 처음에 "(광고)"라는 문구를 표기하고 전송 내용 중에 전송 자의 연락처를 명시하도록 조치합니다.</li>
				</ol>
			</div>
		</section>
		<hr>
		<section>
			<input type="checkbox" title="열기" id="acc12" class="a11y-hidden">
			<div class="acco-tit">
				<h3>12. 권익침해 구제방법</h3>
				<div class="privacy-label-wrap">
					<span class="privacy-label" data-requirement="10"><span class="a11y-hidden">권익침해</span></span>
				</div>
				<label data-acco="acc12" for="acc12"><span class="a11y-hidden">열기</span></label>
			</div>
			<div class="acco-pnl" data-acco="acc12">
				<p>이용자는 아래의 기관에서 개인정보침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.</p>
				<div class="tbl-wrap">
				<table>
					<caption>'개인정보 침해신고센터, 개인정보분쟁조정위원회, 경찰청 사이버수사국, 대검찰청 사이버수사과' 정보 테이블</caption>
					<thead>
						<tr>
							<th scope="col">개인정보 침해신고센터</th>
							<th scope="col">개인정보분쟁조정위원회</th>
							<th scope="col">경찰청 사이버수사국</th>
							<th scope="col">대검찰청 사이버수사과</th>
						</tr>
					</thead>
					<tbody class="tc">
						<tr>
							<td>
								<a href="https://privacy.kisa.or.kr/" target="_blank">privacy.kisa.or.kr</a><br>
								(국번없이 118)
							</td>
							<td>
								<a href="https:/www.kopico.go.kr/" target="_blank">www.kopico.go.kr</a><br>
								(국번없이 1833-6972)
							</td>
							<td>
								<a href="https://ecrm.police.go.k" target="_blank">ecrm.cyber.go.kr</a><br>
								(국번없이 182)
							</td>
							<td>
								<a href="https://spo.go.k" target="_blank">spo.go.kr</a><br>
								(국번없이 1301)
							</td>
						</tr>
					</tbody>    
				</table>
				</div>
			</div>
		</section>  
		<hr>      
		<section>
			<input type="checkbox" title="열기" id="acc13" class="a11y-hidden">
			<div class="acco-tit">
				<h3>13. 개인정보 보호책임자에 관한 사항</h3>
				<div class="privacy-label-wrap">
					<span class="privacy-label" data-requirement="7"><span class="a11y-hidden">보호책임자</span></span>
				</div>
				<label data-acco="acc13" for="acc13"><span class="a11y-hidden">열기</span></label>
			</div>
			<div class="acco-pnl" data-acco="acc13">
				<ol class="list-base" data-number="base" data-shape="round">
					<li>KB 오케어X는 이용자가 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 이용자에게 고지한 사항들에 반하는 사고가 발생할 시에 그 책임은 KB 오케어X에 있습니다.</li>
					<li>그러나 기술적인 보완 조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물 내용에 의한 각종 분쟁에 관해서는 KB 오케어X는 책임을 지지 않습니다.</li>
					<li>
						이용자의 개인정보를 보호하는 책임자는 다음과 같으며 개인정보 관련 문의사항에 성실하게 답변해드리고 있습니다.
						<div class="tbl-wrap">
						<table>
							<caption>'구분, 부서, 이름, 전자우편, 연락처' 정보 테이블</caption>
							<thead>
								<tr>
									<th scope="col">구분</th>
									<th scope="col">부서</th>
									<th scope="col">이름</th>
									<th scope="col">전자우편</th>
									<th scope="col">연락처</th>
								</tr>
							</thead>
							<tbody class="tc">
								<tr>
									<td>개인정보 보호책임자</td>
									<td rowspan="2">사업전략본부</td>
									<td>민경필</td>
									<td rowspan="2">privacy@kbhc.co.kr</td>
									<td rowspan="2">02-2135-5062</td>
								</tr>
								<tr>
									<td>개인정보 보호관리자</td>
									<td>최범진</td>
								</tr>
							</tbody>
						</table> 
						</div>           
					</li>
				</ol>
			</div>
		</section>
		<hr>
		<section>
			<input type="checkbox" title="열기" id="acc14" class="a11y-hidden">
			<div class="acco-tit">
				<h3>14. 개인정보처리방침의 변경</h3>
				<div class="privacy-label-wrap">
					<span class="privacy-label" data-requirement="3"><span class="a11y-hidden">처리방침변경</span></span>
				</div>
				<label data-acco="acc14" for="acc14"><span class="a11y-hidden">열기</span></label>
			</div>
			<div class="acco-pnl" data-acco="acc14">
				<ol class="list-base" data-number="base" data-shape="round">
					<li>공고 일자 : 2023년 12월 27일</li>
					<li>시행 일자 : 2023년 12월 27일</li>
				</ol>
			</div>
		</section>
	</article>
</template>

<style scoped>
	* {box-sizing:border-box;}
	html{font-size: 62.5%;}
	body.cke_editable{padding:2rem; margin: 0; overflow-y: overlay;}
	.scroll-no {overflow: hidden;padding-right: 1.7rem; }

</style>

<script>
export default {
	name: 'PersonXV15',
}
</script>