import axios from "axios";
import router from "@/router";
import store from "@/store";

const DEBUG = process.env.NODE_ENV === "development";
const QA = process.env.NODE_ENV === "qa";

const VUE_APP_OCP_APIM_KEY = process.env.VUE_APP_OCP_APIM_KEY;
//console.log(VUE_APP_OCP_APIM_KEY);

const axiosSales = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL_SALES,
  timeout: 2000,
});

axiosSales.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axiosSales.defaults.headers.common["Access-Control-Allow-Headers"] = "*";
axiosSales.defaults.headers.common["Access-Control-Allow-Methods"] = "*";
axiosSales.defaults.headers.common["Ocp-Apim-Subscription-Key"] =
  VUE_APP_OCP_APIM_KEY;

/**
 * 요청 인터셉터
 */
axiosSales.interceptors.request.use(
  (config) => {
    if (DEBUG || QA) {
      console.debug("sales api request config ", config);
    }

    //+헤더설정
    //config.headers.authorization = "my secret token";
    //config.headers.XXXX = "..."
    return config;
  },
  (error) => {
    console.error("[요청오류]", error);
    //    return Promise.reject(error);

    store.commit("showModalAlert", {
      message: "[요청오류] 서버에 연결할 수 없습니다.",
    });
    return new Promise(() => {});
  }
);

/**
 * 응답 인터셉터
 */
axiosSales.interceptors.response.use(
  (response) => {
    //console.debug("response.status", response.status);

    return response;
  },
  (error) => {
    console.debug(error);
    if (error.response && error.response.status) {
      console.error("server-api response error", error.response);

      switch (error.response.status) {
        case 400:
          store.commit("showModalAlert", {
            message: "[응답오류]<br>잘못된 요청입니다.",
          });

          return new Promise(() => {});
        case 404:
          router.push("/error/404").catch(() => {});
          return new Promise(() => {});
        case 500:
          router.push("/error/500").catch(() => {});
          return new Promise(() => {});
        default:
          return Promise.reject(error);
      }
    }

    if (!error.response) {
      console.error("server-api response error", error);
      alert("API통신이 원할하지 않습니다.");
      return new Promise(() => {});
    }

    router.push("/error/404").catch(() => {});
    return Promise.reject(error);
  }
);

const _testAPI = async () => {
  const res = await axiosSales.post("/api/v1/sales/test", {
    input: "value",
  });

  if (res.status == 200) {
    return res.data;
  }
};

/**
 * IF-MGW-3802 영업코드 검증(담당자 조회)
 * @returns
 */
const _getSalesCodeInfo = async (salesCode) => {
  const res = await axiosSales.post("/v1/sales/verify-code", {
    salesCode: salesCode,
  });

  if (res.status == 200) {
    return res.data;
  }
};

/**
 * IF-MGW-3803 영업소개 소개기업 등록
 * @param {*} applyForm
 * @returns
 */
const _applyMembership = async (applyForm) => {
  const res = await axiosSales.post("/v1/sales/apply", applyForm);

  if (res.status == 200) {
    return res.data;
  }
};

export { _testAPI, _getSalesCodeInfo, _applyMembership };
