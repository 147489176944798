import AgreementPage from "@/views/terms/AgreementPage.vue";
import PersonPage from "@/views/terms/PersonPage.vue";
import LocalServicePage from "@/views/terms/LocalServicePage.vue";

const terms = [
  {
    path: "/terms/agreement",
    name: "AgreementPage",
    component: AgreementPage,
  },
  {
    path: "/terms/person",
    name: "PersonPage",
    component: PersonPage,
  },
  {
    path: "/terms/localservice",
    name: "LocalServicePage",
    component: LocalServicePage,
  },
];

export default terms;
