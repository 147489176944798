/**
 * ASIS 홈페이지 링크 연결
 */
const bcc = {
  path: "bcc",
  children: [
    {
      path: "Main.do",
      redirect: { name: "MainPage" },
    },
    {
      path: "About.do",
      redirect: { name: "MainPage" },
    },
    {
      path: "FAQList.do",
      redirect: { name: "MainPage" },
    },
    {
      path: "NoticeList.do",
      redirect: { name: "PressPage", query: { pageNum: "0" } },
    },
    {
      path: "RecruitMgmtList.do",
      redirect: { name: "RecruitPage" },
    },
    {
      path: "Agreement.do",
      redirect: { name: "AgreementPage" },
    },
    {
      path: "Person.do",
      redirect: { name: "PersonPage" },
    },
    {
      path: "LocaBasedService.do",
      redirect: { name: "LocalServicePage" },
    },
  ],
};

export default bcc;
