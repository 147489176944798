import { axiosHomepage } from "@/api";
export const name = "common";

/**
 *
 * @param {*} grpId 코드그룹
 * @returns
 */
const _getListCodeByGrpId = async (data) => {
  console.log(data);

  const res = await axiosHomepage.post("/v1/code/usr/grpList", {
    grpList: data,
  });

  if (res.status == 200) {
    console.log("status", res.status);

    return res.data.data;
  }
};

/**
 * 첨부파일 목록 조회
 * @param {*} tmplDocTp  APLCN_DOC:입사지원서
 */
const _getAttachFileList = async (ntcFg, ntcNo) => {
  const res = await axiosHomepage.get("/v1/file/list/" + ntcFg + "/" + ntcNo);
  if (res.status == 200) {
    return res.data.data;
  }
};

const _logUserActivity = async ({ fullPath }) => {
  console.log("call_logUserActivity", fullPath);
};

export { _getListCodeByGrpId, _getAttachFileList, _logUserActivity };
