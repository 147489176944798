import { axiosHomepage } from "@/api";

export const name = "news";

const API_LIST_NTC = "/v1/ntc/usr/list?ntcFg=";

/**
 * 소식 목록
 * @param {*} pageNum
 */
const _getPage = async (ntcFg, pageNum, sizeUintPage) => {
  const res = await axiosHomepage.get(
    API_LIST_NTC + ntcFg + "&page=" + pageNum + "&size=" + sizeUintPage
  );

  if (res.status == 200) {
    return res.data.data;
  }
};

/**
 * 소식 상세
 * @param {*} ntcNo
 */
const _getDetail = async (ntcNo) => {
  const res = await axiosHomepage.get("/v1/ntc/usr/" + ntcNo);
  if (res.status == 200) {
    return res.data.data;
  }
};

export { _getPage, _getDetail };
