<script setup>
import { createApp, reactive, shallowRef, ref } from "vue";

import { onMounted } from "vue";
import { UI } from "@/resources/js/ui.common";
import { LocalServiceV10, LocalServiceV11, LocalServiceV12} from "@/components/terms";

const app = createApp({});

app
.component('LocalServiceV10', LocalServiceV10)
.component('LocalServiceV11', LocalServiceV11)
.component('LocalServiceV12', LocalServiceV12);

let terms_ver = window.location.href;
terms_ver = terms_ver.split('?ver=')[1];
terms_ver = terms_ver ?? 'V12';
let current_ver = LocalServiceV12;

switch (terms_ver) {
  case 'V10' : current_ver = LocalServiceV10; break;
  case 'V11' : current_ver = LocalServiceV11; break;
  case 'V12' : current_ver = LocalServiceV12; break;
  default : current_ver = LocalServiceV11; break;
}

const verSelect = ref(terms_ver);
const author = reactive({
  selectList: [
    { date: "2024-03-20", value: "V12" },
    { date: "2023-12-21", value: "V11" },
    { date: "2023-01-20", value: "V10" },
  ],
  currentComponent : shallowRef(current_ver)
});

const changeComponent = (event) => {
  switch(event.target.value){
    case "V12" : author.currentComponent = shallowRef(LocalServiceV12); break;
    case "V11" : author.currentComponent = shallowRef(LocalServiceV11); break;
    case "V10" : author.currentComponent = shallowRef(LocalServiceV10); break;
  }
}

onMounted(() => {
  UI.init();
});
</script>

<template>
  <div id="container" data-ui-page="terms">
    <div id="content" class="content">
      <div class="content-wrap">
        <div class="section">
          <div class="comp">

            <div class="terms-title">
              <h2 class="h2">위치기반서비스 이용약관</h2>
              <div class="terms-date">
                <select @change="changeComponent($event)" v-model="verSelect">
                  <option
                    v-for="(item, index) in author.selectList"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.date }}
                  </option>
                </select>
              </div>
            </div>
            <KeepAlive>
              <component :is="author.currentComponent" />
            </KeepAlive>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>