<template>
  <div id="container" data-ui-page="terms">
    <div id="content" class="content">
      <div class="content-wrap">
        <div class="section">
          <div class="comp">
            <div class="terms-title">
              <h2 class="h2">서비스이용약관</h2>
              <div class="terms-date">
                <select @change="changeComponent($event)">
                  <option
                    v-for="(item, index) in selectList"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.date }}
                  </option>
                </select>
              </div>
            </div>
            <component :is="currentComponent" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UI } from "@/resources/js/ui.common";
import AgreementV10 from "@/components/terms/Agreement_v10.vue";

export default {
  name: "agreementPage",
  components: {
    AgreementV10,
  },
  data() {
    return {
      selectList: [{ date: "2022-02-05", value: "V10" }],
      currentComponent: AgreementV10.name,
    };
  },
  methods: {
    changeComponent(event) {
      // console.log(event.target.value)
      this.currentComponent = "Agreement" + event.target.value;
    },
  },
  mounted() {
    UI.init(this);
    this.currentComponent = "AgreementV10";
  },
};
</script>
