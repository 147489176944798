/**
 *
 * @param {*} state
 * @param {*} data
 */
export function setListCodeByGrpId(state, data) {
  const rows = Object.values(data);
  //console.log(rows[0]);
  state.mapCodesByGrpId = data || {};
  state.codes = rows[0] || {};
}

/**
 * Alert 모달 보여주기
 * @param {} state
 * @param {*} message
 */
export function showModalAlert(state, { message }) {
  //console.log(rows[0]);
  state.modalAlert.showModal = true;
  state.modalAlert.message = message;
}

/**
 * Alert 모달 닫기
 * @param {} state
 */
export function closeModalAlert(state) {
  //console.log(rows[0]);
  state.modalAlert.showModal = false;
  state.modalAlert.message = "";
}
