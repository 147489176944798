import * as sales from "@/api/sales";

export async function getSalesReferral({ commit }, referralCode) {
  console.log(referralCode);

  const data = await sales._getSalesCodeInfo(referralCode);

  console.log("SalesReferralInfo", data);
  commit("applyClose", "close");
}

export async function applyMembership({ commit }, form) {
  console.log(form);

  const data = await sales._testAPI();

  console.log(data);
  commit("applyClose", "close");
}
