<template>
	<div class="terms">
		<h2>개인정보처리방침</h2>
		<p>'KB헬스케어'(이하 '회사')가 운영하는 ‘오케어(O Care)’는 개인정보보호법 제30조 의거 이용자의 개인정보보호와 권익을 보호하고 관련된 고충 및 애로사항을 신속하게 처리하기 위해 아래의 개인정보처리방침을 제정·운영하고 있습니다.</p>
		<p>저희 KB헬스케어는 관계법령에서 규정하고 있는 책임과 의무를 준수하고 실천하기 위해 최선의 노력을 하고 있습니다.</p>
		<h4>제1조 개인정보 수집 및 이용 목적</h4>
		<div class="table-wrap">
			<table>
				<caption>제1조 개인정보 수집 및 이용 목적, 자세한 내용 항목으로 이루어진 테이블입니다.</caption>
				<colgroup>
					<col width="30%">
					<col width="auto">
				</colgroup>
				<thead>
					<tr>
						<th scope="col" colspan="2">이용자의 개인정보를 다음의 목적 이외의 용도로는 이용하지 않으며, 이용 목적이 변경될 경우에는 동의를 받아 처리하겠습니다.</th>
					</tr>
					<tr>
						<th scope="col">목적</th>
						<th scope="col">자세한 내용</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row" rowspan="2">1. 회원관리 및 본인확인</th>
						<td>-회원 식별/가입의사 확인, 연령확인, 회원탈퇴 의사 확인 등 회원관리를 위하여 개인정보 이용</td>
					</tr>
					<tr>
						<td>-약관 개정 등의 고지사항 전달, 민원처리, 분쟁해결을 위한 기록 보존 및 활용, 계정도용 및 부정거래 방지 등 이용자 보호 및 서비스 운영</td>
					</tr>
					<tr>
						<th scope="row" rowspan="2">2. 서비스 제공</th>
						<td>-이용자 개인의 건강정보를 데이터베이스에 백업하거나, 단말과 연동하는 목적으로 저장 및 활용</td>
					</tr>
					<tr>
						<td>- 서비스 내 다양한 개별 서비스 제공</td>
					</tr>
					<tr>
						<th scope="row" rowspan="2">3. 신규 서비스 요소의 발굴 및 서비스 개선</th>
						<td>- 서비스 방문 및 이용기록의 분석, 건강관리 및 의료 서비스 연구, 개인정보 및 건강정보에 기반한 맞춤형 개인 서비스 개발 및 제공 등</td>
					</tr>
					<tr>
						<td>-서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계, 프라이버시 보호 측면의 서비스 환경 구축, 맞춤형 서비스 제공, 서비스 개선에 활용</td>
					</tr>
					<tr>
						<th scope="row">4. 마케팅 및 프로모션</th>
						<td>
							-이벤트 정보 제공 및 참여기회 제공<br>
							-서비스 홍보 및 광고성 정보 제공
						</td>
					</tr>
					<tr>
						<th scope="row" rowspan="2">5. 유료서비스 제공에 관한 계약 이행 및 요금 정산</th>
						<td>-유료 서비스 제공 시 전자상거래법 등 관련법에 따른 본인인증, 결제수단에 따른 결제 정보를 이용</td>
					</tr>
					<tr>
						<td>-콘텐츠 제공, 이벤트, 경품 당첨 결과안내 및 상품배송</td>
					</tr>
				</tbody>
			</table>
		</div>
		<h4>제2조 개인정보 수집 항목 및 방법</h4>
		<p>회원 가입 또는 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고 있습니다.</p>
		<div class="table-wrap">
			<table>
				<caption>1) 수집하는 개인정보의 항목으로 이루어진 테이블입니다.</caption>
				<colgroup>
					<col width="20%">
					<col width="auto">
				</colgroup>
				<thead>
					<tr>
						<th scope="col" colspan="2">1) 수집하는 개인정보의 항목</th>
					</tr>
					<tr>
						<th scope="col">구분</th>
						<th scope="col">자세한 내용</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row" rowspan="6">필수항목</th>
						<td>- 개인식별정보</td>
					</tr>
					<tr>
						<td>이름, 생년월일, 성별, 휴대전화번호, PIN번호, 개인연결정보(CI), 중복구분정보(DI), 국적</td>
					</tr>
					<tr>
						<td>- 건강정보</td>
					</tr>
					<tr>
						<td>신장, 체중, 허리둘레</td>
					</tr>
					<tr>
						<td>- 기타 정보</td>
					</tr>
					<tr>
						<td>
							단말기 접속일시, 단말기 아이디, 단말기기 정보(IMEI/UUID) 및 OS정보, 통신사정보, 접속IP 정보, 홈페이지 접속 정보 및 서비스 이용 정보, 쿠키 정보<br>
							* 휴대폰을 통해 O-CARE 앱 접속 시
						</td>
					</tr>
					<tr>
						<th scope="row" rowspan="11">선택항목</th>
						<td>- 개인식별정보</td>
					</tr>
					<tr>
						<td>직업구분, 위치정보, 긴급 연락처 및 관계, 패턴인증, 생체인증</td>
					</tr>
					<tr>
						<td>- 개인 건강정보</td>
					</tr>
					<tr>
						<td>건강 관심사 키워드, 활동량, 영양 및 수분섭취, 수면정보, 심박수, 혈당, 복용약물명, 과거질환명, 보유질환명, 혈액형</td>
					</tr>
					<tr>
						<td>- 건강검진 기록</td>
					</tr>
					<tr>
						<td>
							검진년도, 검진일자, 신장, 체중, 허리둘레, BMI, 시력(좌/우), 청력(좌/우), 혈압(수축기혈압/확장기혈압), 요단백, 혈색소, 식전혈당, 총콜레스테롤, HDL콜레스테롤, 트리클리세라이드, LDL콜레스테롤, 혈청크레아티닌, 신사구체여과율(GFR), AST(SGOT), ALT(SGPT), 감마지피티(y-GPT), 골다공증<br>
							※ 정보주체 별 수집하는 정보가 상이할 수 있음
						</td>
					</tr>
					<tr>
						<td>
							- 건강검진 예약기록 <br>
							검진기관, 예약일, 검진과목
						</td>
					</tr>
					<tr>
						<td>
							- 병원 방문 내역 <br>
							병원명, 진료(방문)일자, 진료형태, 진단명, 방문(입원)일수, 처방회수, 투약(요양)일수, 본인부담금, 공단부담금
						</td>
					</tr>
					<tr>
						<td>
							- 약국 처방 내역<br>
							약국명, 진료(처방)일자, 처방회수, 처방약품명, 처방약품효능, 투약일수
						</td>
					</tr>
					<tr>
						<td>- 기타 정보</td>
					</tr>
					<tr>
						<td>추천인 정보, 서비스 유입경로</td>
					</tr>
				</tbody>
			</table>
		</div>
		<P>2) 개인정보 수집방법</P>
		<ul>
			<li>본인인증을 통해 자동 수집</li>
			<li>이용자가 서비스 내에서 직접 입력하는 정보 수집</li>
			<li>회원의 모바일 기기 내 설치된 건강관련 앱(삼성헬스, 구글피트니스, 애플건강)으로부터 수집</li>
			<li>웨어러블 디바이스 연동을 통해 수집</li>
			<li>고객센터 상담과정에서 수집</li>
		</ul>
		<P>3) 자동수집 장치의 설치와 운영거부에 관한 사항</P>
		<P>서비스 이용 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.</P>
		<ul>
			<li>모바일 기기 이용 시 단말기 식별번호, 단말 OS 정보, Push 수신여부</li>
			<li>이용자의 브라우저 종류 및 OS, 방문 기록(IP Address, 접속시간)</li>
			<li>유료서비스 이용 시 발생한 구매/결제/취소/환불 내역</li>
		</ul>
		<P>자동수집 기록 거부 및 삭제 방법</P>
		<p>[App]</p>
		<ul>
			<li>안드로이드 폰 : 휴대폰 설정 > 앱 관리 > O Care > 저장소 > 사용자데이터 / 캐시 > 데이터 지우기</li>
			<li>아이폰 : 설정 > 앱 관리 > O Care > 저장소 > 데이터 지우기</li>
		</ul>
		<h4>제3조 개인정보 보유 기간 및 파기</h4>
		<p>회사는 이용자의 개인정보는 이용목적이 달성 및 이용자의 서비스 해지 요청이 있을 경우 지체 없이 파기합니다. 단, 법령의 규정에 의하여 보존할 필요성이 있는 경우에는 예외로 합니다.</p>
		<p>회사는 ‘개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 이용자는 휴면계정으로 전환되어, 개인정보를 별도로 분리 보관합니다. 회사는 휴면 전환 30일 전까지 휴면 예정 회원에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 이메일, SMS, App push 등 이용자에게 통지 가능한 방법으로 이용자에게 알립니다.</p>
		<p>다만, 해당 통지 수단에 대한 정보가 부재 또는 오류인 경우에는 개인정보 처리방침의 내용으로 갈음하며, 로그인 할 때 파기 혹은 별도 분리저장에 관하여 사후 통보할 수 있습니다. 분리 보관된 개인정보는 4년간 보관 후 지체없이 파기합니다.</p>
		<p>휴면계정으로 전환을 원하지 않으시는 경우, 휴면계정 전환 전 서비스 내 로그인 하시면 됩니다. 또한, 휴면 계정으로 전환되었더라도 로그인할 경우 이용자의 동의에 따라 휴면계정을 복원하여 정상적인 서비스를 이용할 수 있습니다.</p>
		<p>이 외에 법령에 따라 일정기간 보관해야 하는 개인정보 및 해당 법령은 아래 표와 같습니다.</p>
		<table>
			<caption>개인정보의 처리 및 보유기간 관계법령, 항목으로 이루어진 테이블입니다.</caption>
			<colgroup>
				<col width="auto">
				<col width="35%">
				<col width="25%">
			</colgroup>
			<thead>
				<tr>
					<th scope="col" colspan="3">개인정보의 처리 및 보유기간 관계법령</th>
				</tr>
				<tr>
					<th scope="col">보존 항목</th>
					<th scope="col">근거 법령</th>
					<th scope="col">보존 기간</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>계약 또는 청약철회 등에 관한 기록</td>
					<td rowspan="4">전자상거래 등에서의 소비자 보호에 관한 법률</td>
					<td>5년</td>
				</tr>
				<tr>
					<td>대금결제 및 재화 등의 공급에 관한 기록</td>
					<!-- <td></td> -->
					<td>5년</td>
				</tr>
				<tr>
					<td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
					<!-- <td></td> -->
					<td>3년</td>
				</tr>
				<tr>
					<td>표시/광고에 관한 기록</td>
					<!-- <td></td> -->
					<td>6개월</td>
				</tr>
				<tr>
					<td>전자금융 거래에 관한 기록</td>
					<td>전자금융거래법</td>
					<td>5년</td>
				</tr>
				<tr>
					<td>서비스 방문 기록</td>
					<td>통신비밀보호법</td>
					<td>3개월</td>
				</tr>
			</tbody>
		</table>
		<p>회사는 이용자 개인정보의 보유기간의 경과, 이용목적이 달성되면 재생이 불가능한 방법으로 파기하고 있습니다.</p>
		<p>전자적 파일 형태는 복구 및 재생할 수 없는 기술적 방법을 사용하여 안전하게 삭제하고, 종이에 출력된 개인정보는 분쇄하거나 소각하여 파기합니다.</p>
		<h4>제4조 개인정보 제공</h4>
		<p>회사는 이용자 동의 없이 개인정보를 외부에 공개하지 않습니다.</p>
		<p>다만, 서비스, 콘텐츠, 혜택을 제공하기 위해 외부 제휴사에 개인정보를 제공합니다. 제공이 필요한 경우 회사는 사전에 회원에게 ‘개인정보를 제공받는 자, 제공목적, 제공하는 개인정보 항목, 제공받는 개인정보의 보유 및 이용기간’을 고지하고 이에 대해 명시적·개별적 동의를 얻습니다. 아래 서비스를 이용하지 않는 회원은 개인정보 제공이 발생되지 않습니다.</p>
		<p>[별도 링크 :이용자 동의 후 개인정보 제3자 제공이 발생하는 경우 / 별첨1 참조]</p>
		<p>다만, 아래의 경우에는 예외로 합니다.</p>
		<ul>
			<li>이용자가 사전에 동의한 경우</li>
			<li>통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정개인을 식별할 수 없는 형태로 제공하는 경우</li>
			<li>영업의 양도·합병 등에 관한 사유가 발생하는 경우</li>
			<li>(단, 회사는 영업의 양도 등에 관한 사유가 발생하여 이용자의 개인정보 이전이 필요한 경우, 관계법률에서 규정한 절차와 방법에 따라 개인정보 이전에 관한 사실 등을 사전에 고지하며, 이용자에게는 개인정보 이전에 관한 동의 철회권을 부여합니다.)</li>
			<li>그 밖에 법률에 특별한 규정이 있는 경우</li>
		</ul>
		<h4>제5조 개인정보 위탁</h4>
		<p>회사는 편리하고 더 나은 서비스를 제공하기 위해 아래와 같은 업무를 위탁하고 있으며, 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.</p>
		<p>개인정보의 처리를 위탁하는 경우에는 개인정보 보호의 안전을 기하기 위하여 개인정보보호 관련 지시 엄수, 개인정보에 대한 비밀유지, 제3자 제공의 금지 및 사고 시의 책임부담, 위탁 기간, 처리 종료 후의 개인정보의 반환 또는 파기 등을 명확히 규정하고, 위탁업체가 개인정보를 안전하게 처리하도록 감독합니다.</p>
		<p>업체, 위탁하는 업무의 내용이 변경될 경우, 웹사이트 공지사항(또는 개별공지)을 통하여 알려드리겠습니다.</p>
		<div class="table-wrap">
			<table>
				<caption>수탁업체, 위탁업무 내용, 개인정보 보유 및 이용기간 항목으로 이루어진 테이블입니다.</caption>
				<col width="25%">
				<col width="auto">
				<col width="25%">
				<thead>
					<tr>
						<th scope="col">수탁업체</th>
						<th scope="col">위탁업무 내용</th>
						<th scope="col">개인정보 보유 및 이용기간</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td rowspan="3">㈜유라클</td>
						<td>개인건강기록(PHR)데이터 위수탁 관리</td>
						<td rowspan="8">회원 탈퇴 시 혹은 서비스 이용종료 시, 혹은 위탁 계약 종료까지</td>
					</tr>
					<tr>
						<td>O-CARE 관련 개인정보의 처리 및 전산개발</td>
					</tr>
					<tr>
						<td>문자메시지 및 알림톡 시스템 운영</td>
					</tr>
					<tr>
						<td>MS Azure</td>
						<td>
							클라우드 IT인프라 제공<br>
							데이터 처리 및 저장
						</td>
					</tr>
					<tr>
						<td>GC케어</td>
						<td>검진기관별 예약 및 건강검진정보 수집</td>
					</tr>
					<tr>
						<td>㈜KCB</td>
						<td>본인확인정보의 정확성 여부 확인(본인확인서비스 제공), 연계정보(CI)/중복가입확인정보(DI) 생성 및 전송</td>
					</tr>
					<tr>
						<td>㈜KG이니시스</td>
						<td>결제 및 에스크로 서비스</td>
					</tr>
					<tr>
						<td>㈜제일비엠시</td>
						<td>고객상담, 서비스 운영</td>
					</tr>
				</tbody>
			</table>
		</div>
		<p>* 일부 서비스는 회사와 제휴한 서비스 파트너에서 결제 및 환불 등 서비스 이용에 대한 고객상담을 진행할 수 있습니다.</p>
		<h4>제6조 이용자 및 법정대리인의 권리와 그 행사 방법</h4>
		<p>이용자는 언제든지 자신의 개인정보를 조회하거나 수정할 수 있으며, 수집 및 이용에 대한 동의 철회(가입 해지)를 요청할 수 있습니다.</p>
		<p>만 14세 미만 아동의 경우, 법정대리인이 아동의 개인정보를 조회하거나 수정 및 삭제, 처리정지, 수집 및 이용 동의를 철회할 권리를 가집니다.</p>
		<p>[조회 및 수정] O Care앱에서 “마이페이지 > 내정보 ”에서 자신의 개인정보를 조회하거나 수정할 수 있습니다. 처리가 완료될 때까지 해당 정보를 이용하거나 타인에게 제공하지 않습니다.</p>
		<p>[회원 탈퇴(동의 철회)] O Care앱에서 “마이페이지 > 내정보> 회원탈퇴” 이용하여 탈퇴를 할 수 있습니다. 또한, 1:1문의를 통하여 개인정보를 조회/수정/탈퇴를 요청하실 수 있습니다.</p>
		<h4>제7조 개인정보의 안전성 확보조치</h4>
		<p>회사는 이용자의 개인정보를 안전하게 관리하여 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 최선을 다하고 있으며 필요한 기술적, 관리적 및 물리적 조치를 하고 있습니다.</p>
		<ul>
			<li>개인정보 취급 직원의 최소화 및 교육
				<p>개인정보를 취급하는 직원을 최소화하고, 주기적인 개인정보 보호 교육을 실시하여 개인정보를 관리하는 대책을 시행하고 있습니다.</p>
			</li>
			<li>내부관리계획의 수립 및 시행
				<p>개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</p>
			</li>
			<li>접속기록의 보관 및 위변조 방지
				<p>개인정보 침해사고 발생 시 대응이 용이하도록 개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 1년 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.</p>
			</li>
			<li>개인정보의 암호화
				<p>이용자의 개인정보는 암호화 되어 저장 및 관리되고 있습니다.</p>
			</li>
			<li>해킹 등에 대비한 기술적 대책
				<p>회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 합니다. 또한 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</p>
			</li>
			<li>개인정보에 대한 접근통제 제한
				<p>개인정보를 처리하는 개인정보처리시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위한 조치를 하고 있습니다.</p>
			</li>
		</ul>
		<h4>제8조 개인정보책임자</h4>
		<p>서비스를 이용하시는 과정에서 발생하는 모든 개인정보보호 관련 문의, 불만처리는 개인정보 보호책임자에게 연락해 주시기 바랍니다. 회사는 이용자들의 목소리에 대해 신속하고 충분한 답변을 드릴 것입니다.</p>
			<table>
				<caption>개인정보보호 책임자, 개인정보보호 담당자 항목으로 이루어진 테이블입니다.</caption>
				<colgroup>
					<col width="30%">
					<col width="auto">
				</colgroup>
				<tbody>
					<tr>
						<th scope="row">개인정보보호 책임자</th>
						<td>
							<ul>
								<li>성명: 심병건</li>
								<li>직위: 본부장</li>
								<li>이메일: <a href="mailto:bgshim@kb-health.com">bgshim@kb-health.com</a></li>
							</ul>
						</td>
					</tr>
					<tr>
						<th scope="row">개인정보보호 담당자</th>
						<td>
							<ul>
								<li>성명: 최범진</li>
								<li>직위: 프로</li>
								<li>이메일: <a href="mailto:bjchoi@kb-health.com">bjchoi@kb-health.com</a></li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>
		<p>개인정보 침해에 대한 신고, 상담 등이 필요하신 경우는 아래 기관에 문의하실 수 있습니다.</p>
		<div class="table-wrap">
			<table>
				<caption>개인정보침해신고센터, 대검찰청 사이버수사과, 경찰청 사이버안전국, 개인정보분쟁조정위원회 항목으로 이루어진 테이블입니다.</caption>
				<colgroup>
					<col width="auto">
					<col width="auto">
					<col width="auto">
					<col width="auto">
				</colgroup>
				<thead>
					<tr>
						<th scope="col">개인정보침해신고센터</th>
						<th scope="col">대검찰청 사이버수사과</th>
						<th scope="col">경찰청 사이버안전국</th>
						<th scope="col">개인정보분쟁조정위원회</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<a href="mailto:privacy.kisa.or.kr">privacy.kisa.or.kr</a>
							<br>
							(국번없이 118)
						</td>
						<td>
							<a href="mailto:spo.go.kr">spo.go.kr</a>
							<br>
							(국번없이 1301)
						</td>
						<td>
							<a href="mailto:cyberbureau.police.go.kr">cyberbureau.police.go.kr</a>
							<br>
							(국번없이 182)
						</td>
						<td>
							<a href="mailto:cyberbureau.police.go.kr">cyberbureau.police.go.kr</a>
							<br>
							(국번없이 1833-6972)
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<h4>제9조 개인정보처리방침의 변경</h4>
		<p>현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지 '공지사항'을 통해 고지할 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일전에 고지합니다</p>
		<ul>
			<li>공고 일자 : 2022년 1월 28일</li>
			<li>시행 일자 : 2022년 4월 4일</li>
		</ul>
		<p>[별첨1] 이용자 동의 후 개인정보 제3자 제공이 발생하는 경우</p>
		<p>회사에서 제공하는 서비스, 콘텐츠, 혜택을 제공하기 위하여 회사와 계약을 체결한 파트너 등에 개인정보를 제공하는 목록입니다. 제공이 필요한 경우 회사는 사전에 회원에게 ‘개인정보를 제공받는 자, 제공목적, 제공하는 개인정보 항목, 제공받는 개인정보의 보유 및 이용기간’을 고지하고 이에 대해 명시적·개별적 동의를 얻습니다. 아래 서비스를 이용하지 않는 회원은 개인정보 제공이 발생되지 않습니다.</p>
		<div class="table-wrap">
			<table>
				<caption>제공받는 자, 제공목적, 제공하는개인정보, 제공받는 자의보유/이용기간 항목으로 이루어진 테이블입니다.</caption>
				<colgroup>
					<col>
				</colgroup>
				<thead>
					<tr>
						<th scope="col">제공받는 자</th>
						<th scope="col">제공목적</th>
						<th scope="col">제공하는개인정보</th>
						<th scope="col">제공받는 자의보유/이용기간</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>테라젠바이오</td>
						<td>
							유전자 검사 분석 및 키트 배송<br>
							- 1:1상담, Q&A 상담
						</td>
						<td>성명, 생년월일, 전화번호, 이메일주소, 주소</td>
						<td rowspan="7">
							이용 목적 달성 또는 보유기관 경과시<br>
							(단, 관계법령에 정해진 규정이 있는 경우 해당 기간 동안 보관 후 파기)
						</td>
					</tr>
					<tr>
						<td rowspan="2">휴노</td>
						<td>
							심리 검사 및 검사해석<br>
							- 각종 심리 검사 및 해석 서비스
						</td>
						<td>성명, 생년월일, 성별, 휴대전화번호, 이메일주소, 심리검사결과</td>
					</tr>
					<tr>
						<td>
							나. 대면/비대면 심리상담 서비스<br>
							- 대면 심리 상담서비스 예약 대행<br>
							- 비대면(유선,화상,채팅) 상담 서비스 제공
						</td>
						<td>성명, 생년월일, 성별, 휴대전화번호, 이메일주소</td>
					</tr>
					<tr>
						<td>AAI헬스케어</td>
						<td>
							당뇨 관리 서비스 제공<br>
							1:1상담, Q&A상담
						</td>
						<td>성명, 성별, 나이, 전화번호, 건강검진정보, 혈당정보(당화혈색소 포함), 혈압정보, 식단정보, 복약기록정보, 수면시간, 체중, 걸음수, 설문정보</td>
					</tr>
					<tr>
						<td>아이센스</td>
						<td>건강관리기기 배송</td>
						<td>성명, 전화번호, 주소</td>
					</tr>
					<tr>
						<td>GC케어</td>
						<td>
							건강검진 예약, 건강검진 평가 및 결과 확인<br>
							건강검진 예약 서비스 제공, 건강검진 결과 확인서비스 제공
						</td>
						<td>이메일주소, 암호화된 동일인 식별정보(CI)</td>
					</tr>
					<tr>
						<td>㈜코드에프</td>
						<td>건강평가서비스, 보험 및 금융상품 혜택 수준 제공</td>
						<td>공인인증서 DN값</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
export default {
  name: 'PersonV11',
}
</script>