<template>
	<div class="terms">
		<h2>오케어 위치기반서비스 이용약관</h2>
		<h3>제 1 장 총칙</h3>
		<h4>제 1 조 (목적)</h4>
		<p>
			본 약관은 회원(오케어서비스) 약관에 동의한 자를 말합니다. (이하 “회원”이라고 합니다.)
			<br>
			KB헬스케어가 제공하는 오케어서비스 (이하 "서비스"라고 합니다)를 이용함에 있어 KB헬스케어와 회원의 권리 및 의무, 기타 제반 사항을 정함을 목적으로 합니다.
		</p>
		
		<h3>제 2 장 서비스의 이용</h3>
		
		<h4>제 2 조 (가입자격)</h4>
		<p>서비스에 가입할 수 있는 회원은 위치기반서비스를 이용할 수 있는 이동전화 단말기의 소유자 본인이어야 합니다.</p>
		
		<h4>제 3 조 (서비스 가입)</h4>
		<p>KB헬스케어는 다음 각 호의 가입신청을 승낙하지 않을 수 있습니다.</p>
		<ol>
			<li>① 실명이 아니거나 다른 사람의 명의를 사용하는 등 허위로 신청하는 경우</li>
			<li>② 고객 등록 사항을 누락하거나 오기하여 신청하는 경우</li>
			<li>③ 공공질서 또는 미풍양속을 저해하거나 저해할 목적을 가지고 신청하는 경우</li>
			<li>④ 기타 KB헬스케어가 정한 이용신청 요건이 충족되지 않았을 경우</li>
		</ol>
		
		<h4>제 4 조 (서비스 해지)</h4>
		<p>서비스 해지를 희망하는 회원은 회사가 정한 절차를 통해 서비스 해지를 신청할 수 있습니다.</p>
		
		<h4>제 5 조 (이용약관의 효력 및 변경)</h4>
		<ol>
			<li>① 회원이 본 약관에 동의하고 KB헬스케어가 정한 소정의 절차에 따라 회원이 서비스의 이용자로 등록됨으로써 본 약관의 효력이 발생합니다.</li>
			<li>② 회원이 온라인에서 본 약관의 "동의하기" 버튼을 클릭하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 봅니다.</li>
			<li>③ KB헬스케어는 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 소비자기본법, 약관의 규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</li>
			<li>④ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 회사 홈페이지에 최소 30일 전부터 적용일자 전까지 공지하여야 합니다. 회원으로서 서비스를 계속 제공받고자 하는 고객은 약관 변경에 동의하여야만 하며, 공지일자로부터 30일 이내에 약관에 대한 거부의사를 명확히 표명하지 않은 경우 약관에 동의한 것으로 봅니다.</li>
		</ol>
		
		<h4>제 6 조 (관계법령의 적용)</h4>
		<p>본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대하여는 관계법령 또는 상관례에 따릅니다.</p>
		
		<h4>제 7 조 (서비스의 내용) KB헬스케어가 제공하는 서비스는 아래와 같습니다.</h4>
		<table>
			<caption>서비스명, 서비스 내용 항목으로 이루어진 테이블입니다.</caption>
			<colgroup>
				<col />
				<col />
			</colgroup>
			<thead>
				<tr>
					<th scope="col">서비스명</th>
					<th scope="col">서비스 내용</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>오케어서비스</td>
					<td>약국찾기, 병원찾기</td>
				</tr>
			</tbody>
		</table>
		<h4>제 8 조 (서비스 이용요금)</h4>
		<ol>
			<li>① KB헬스케어가 제공하는 서비스는 기본적으로 무료입니다. 단, 별도의 유료 서비스의 경우 해당 서비스에 명시된 요금을 지불하여야 사용 가능합니다.</li>
			<li>② KB헬스케어는 유료 서비스 이용요금을 KB헬스케어와 계약한 전자지불업체에서 정한 방법에 의하거나 KB헬스케어가 정한 청구서에 합산하여 청구할 수 있습니다.</li>
			<li>③ 유료서비스 이용을 통하여 결제된 대금에 대한 취소 및 환불은 KB헬스케어의 결제 이용약관과 관계법령에 따릅니다.</li>
			<li>④ 무선 서비스 이용 시 발생하는 데이터 통신료는 회원이 부담하며 가입한 각 이동통신사의 정책에 따릅니다.</li>
			<li>⑤ MMS 등으로 게시물을 등록할 경우 발생하는 요금은 회원이 부담하며 각 이동통신사의 정책에 따릅니다.</li>
		</ol>
		<h4>제 9 조 (서비스내용변경 통지 등)</h4>
		<ol>
			<li>① KB헬스케어가 서비스 내용을 변경하거나 종료하는 경우 KB헬스케어는 회원의 등록된 전자우편 주소로 이메일을 통하여 서비스 내용의 변경 사항 또는 종료를 통지할 수 있습니다.</li>
			<li>② ①항의 경우 불특정 다수인을 상대로 통지를 함에 있어서는 웹사이트 등 기타 KB헬스케어의 공지사항을 통하여 회원들에게 통지할 수 있습니다.</li>
		</ol>
		<h4>제 10 조 (서비스이용의 제한 및 중지)</h4>
		<ol>
			<li>① KB헬스케어는 아래 각 호의 1에 해당하는 사유가 발생한 경우에는 회원의 서비스 이용을 제한하거나 중지시킬 수 있습니다.
				<ol>
					<li>1. 회원이 KB헬스케어 서비스의 운영을 고의 또는 중과실로 방해하는 경우</li>
					<li>2. 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우</li>
					<li>3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</li>
					<li>4. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때</li>
					<li>5. 기타 중대한 사유로 인하여 KB헬스케어가 서비스 제공을 제한•중지하는 것이 적당하다고 인정되는 경우</li>
				</ol>
			</li>
			<li>② KB헬스케어는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 해당사실을 인터넷 등에 </li>
		</ol>

		<h3>제 3 장 개인위치정보의 보호</h3>

		<h4>제 11조 (위치정보 수집 방법)</h4>
		<p>"회사"는 다음과 같은 방식으로 개인위치정보를 수집합니다.</p>
		<ol>
			<li>① 단말기를 이용한 기지국 기반(Cell ID방식)의 실시간 위치정보 수집</li>
			<li>② GPS칩이 내장된 전용 단말기를 통해 수집되는 GPS 정보를 통한 위치정보 수집.</li>
			<li>③ Wi-Fi칩이 내장된 전용 단말기를 통해 수집되는 Wi-Fi 정보를 통한 위치정보 수집</li>
		</ol>
		<h4>제 12 조 (개인위치정보의 이용 또는 제공)</h4>
		<ol>
			<li>① KB헬스케어는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 회원이 본 약관에 동의하면 위치정보 이용에 동의한 것으로 간주됩니다.</li>
			<li>② KB헬스케어는 회원이 제공한 개인위치정보를 해당 회원의 동의 없이 서비스 제공 이외의 목적으로 이용하지 않습니다. 단, 동조 3항과 같이 고객이 미리 요청한 경우 해당 내용을 고객이 지정한 휴대전화나 이메일 주소로 통보할 수 있습니다.</li>
			<li>③ KB헬스케어는 개인위치정보를 회원이 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치로 매회 회원에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다. 단, 아래 각 호의 1에 해당하는 경우에는 회원이 미리 특정하여 지정한 통신 단말장치 또는 전자우편주소로 통보합니다.
				<ol>
					<li>1. 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우</li>
					<li>2. 회원이 온라인 게시 등의 방법으로 통보할 것을 미리 요청한 경우</li>
				</ol>
			</li>
		</ol>
		<h4>제 13 조 (개인위치정보의 보유기간 및 이용기간)</h4>
		<p>KB헬스케어가 회원의 개인위치정보를 이용하였다면 KB헬스케어는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항의 규정에 따라 기록 보존해야 하는 위치정보이용 제공사실 확인자료 이외의 해당 개인위치정보를 위치정보법 제23조에 따라 고객이 동의한 목적범위 내에서 이용하고 고객의 불만에 응대하기 위하여 6개월간 보유하며 6개월이 지나면 즉시 파기합니다.</p>
		<h4>제 14 조 (개인위치정보 주체의 권리)</h4>
		<ol>
			<li>① 회원은 KB헬스케어에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다.</li>
			<li>② 회원은 KB헬스케어에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있습니다.</li>
			<li>③ 회원은 KB헬스케어에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 KB헬스케어는 정당한 사유 없이 회원의 요구를 거절할 수 없습니다.
				<ol>
					<li>1. 본인에 대한 위치정보 수집, 이용, 제공사실 확인자료</li>
					<li>2. 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게 제공된 이유 및 내용</li>
				</ol>
			</li>
			<li>④ 회원은 제1항 내지 제3항의 권리행사를 KB헬스케어가 정한 소정의 절차를 통해 행사할 수 있습니다.</li>
		</ol>
		<h4>제 15 조 (법정대리인의 권리)</h4>
		<ol>
			<li>① KB헬스케어는 14세 미만의 아동은 회원으로 받지 않습니다.</li>
		</ol>
		<h4>제 16 조 (위치정보관리책임자의 지정)</h4>
		<ol>
			<li>① KB헬스케어는 위치정보를 적절히 관리․보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영합니다.</li>
			<li>② 위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본 약관의 부칙에 따릅니다.</li>
		</ol>
		<h3>제 4 장 기타</h3>
		<h4>제 17 조 (손해배상)</h4>
		<ol>
			<li>① KB헬스케어가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한 행위로 회원에게 손해가 발생한 경우 회원은 KB헬스케어에 대하여 손해배상 청구를 할 수 있습니다. 이 경우 KB헬스케어는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.</li>
			<li>② 회원이 고의나 과실로 본 약관의 규정을 위반하여 KB헬스케어에 손해가 발생한 경우 회원은 KB헬스케어에 발생하는 모든 손해를 배상해야 합니다.</li>
		</ol>
		<h4>제 18 조 (면책)</h4>
		<ol>
			<li>① KB헬스케어는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
				<ol>
					<li>1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</li>
					<li>2. 제3자의 고의적인 서비스 방해가 있는 경우</li>
					<li>3. 회원의 귀책사유로 서비스 이용에 장애가 있는 경우</li>
					<li>4. 기타 KB헬스케어의 고의∙과실이 없는 사유로 인한 경우</li>
				</ol>
			</li>
			<li>② KB헬스케어는 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 회원의 손해에 대하여는 책임을 부담하지 아니합니다.</li>
			<li>③ KB헬스케어는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대해 책임을 지지 않으며, 그 밖의 서비스를 통하여 얻은 자료 때문에 손해를 보았을 때에도 책임을 지지 않습니다.</li>
			<li>④ KB헬스케어에서 제공하는 서비스 및 서비스를 이용하여 얻은 정보에 대한 최종 판단은 회원이 직접 해야 하고, 그에 따른 책임은 전적으로 회원 자신에게 있으며, KB헬스케어는 그 때문에 발생하는 손해에 대해 책임을 부담하지 않습니다.</li>
		</ol>
		<h4>제 19 조 (분쟁의 조정 및 전속관할)</h4>
		<ol>
			<li>① KB헬스케어 또는 고객은 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 양 당사자는 위치정보의 보호 및 이용 등에 관한 법률 제28조(방송통신위원회의 재정), 개인정보보호법 제43조(조정의 신청)의 규정에 따라 방송통신위원회 또는 개인정보분쟁조정위원회에 재정 또는 분쟁조정을 신청할 수 있습니다.</li>
			<li>② 동조 제1항으로도 분쟁이 해결되지 않으면 제소 당시 이용자의 주소에 따르며, 주소가 없으면 거소를 관할하는 지방법원의 전속관할로 합니다. 다만 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 이용자가 외국 거주자일 때에는 민사소송법상의 관할법원에 제기합니다.</li>
		</ol>
		<h4>제 20 조 (KB헬스케어의 연락처) KB헬스케어의 상호 및 주소 등은 다음과 같습니다.</h4>
		<ol>
			<li>1. 상 호 : ㈜KB헬스케어</li>
			<li>2. 주소 : 서울시 강남구 테헤란로 334, 15층</li>
			<li>3. 전화번호 : 1544-3677</li>
		</ol>
		<h4>부 칙</h4>
		<p>제1조 (시행일) 이 약관은 2023년 1월 20일부터 시행합니다.</p>
		<p>제2조 위치정보관리책임자는 2023년 1월 20일을 기준으로 다음과 같이 지정합니다.</p>
		<ol>
			<li>1. 소 속 : 사업전략본부</li>
			<li>2. 성 명 : 민경필</li>
			<li>3. 이메일 : privacy@kbhc.co.kr</li>
		</ol>
	</div>
</template>

<script>
export default {
	name: 'LocalserviceV10',
}
</script>