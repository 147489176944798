import axios from "axios";
import store from "@/store";
import { axiosHomepage } from "@/api";
import { saveAs } from "file-saver";
export const name = "file";

/**
 * 템플릿 문서 Blob 경로 조회
 * @param {*} tmplDocTp  APLCN_DOC:입사지원서
 */
const _getTemlatePath = async (tmplDocTp, fileNm, target) => {
  const res = await axiosHomepage.post("/v1/file/template/path", {
    tmplDocTp: tmplDocTp,
  });

  if (res.status == 200) {
    const link = document.createElement("a");
    link.href = res.data.data;
    link.download = fileNm;
    link.target = target || "";
    link.click();
    URL.revokeObjectURL(link.href);
    return res.data.data;
  }
};

/**
 * 템플릿 문서 다운로드
 * @param {} tmplDocTp
 * @returns
 */
const _downloadTemplate = async ({ tmplDocTp, fileName }) => {
  //console.debug("_downloadTemplate tmplDocTp=", { tmplDocTp, fileName });

  const res = await axiosHomepage.post(
    "/v1/file/download/template",
    {
      tmplDocTp: tmplDocTp,
    },
    { responseType: "blob" }
  );

  if (res.status == 200) {
    var blob = new Blob([res.data]);
    saveAs(blob, fileName);
  }
};

/**
 * tmplDocTp 에 따라 다운로드 경로를 응답받은 후, blob 다운로드 실행
 * @param {*} tmplDocTp APLCN_DOC:입사지원서
 * @param {*} fileNm
 */
const downloadByTemlatePath = async (tmplDocTp, fileNm) => {
  const res = await axiosHomepage.post("/v1/file/template/path", {
    tmplDocTp: tmplDocTp,
  });

  if (res.status == 200) {
    console.debug("_getTemlatePath", res.data.data);
    if (checkPdf(fileNm)) {
      openPdf(res.data.data, fileNm);
    } else {
      downloadByFullPath({ fileRoute: res.data.data, orginFileNm: fileNm });
    }
  }
};

/**
 * 첨부파일 링크 다운로드
 * @param {} param0
 */
const downloadByFullPath = ({ fileRoute, orginFileNm }) => {
  axios
    .get(fileRoute, {
      responseType: "blob",
    })
    .then(function (res) {
      if (res.status == 200) {
        saveAs(new Blob([res.data]), orginFileNm);
      }
    })
    .catch(function (error) {
      console.error("파일다운로드 오류", error);
      store.commit("showModalAlert", {
        message:
          "[응답오류]<br>파일을 다운로드할 수 없습니다.<br> ( " +
          orginFileNm +
          " )",
      });
    });
};

/**
 * PDF 확인
 * @param {} fileName
 * @returns
 */
const checkPdf = (fileName) => {
  return /\.(pdf)$/i.test(fileName);
};

/**
 * PDF 새창 열기
 * @param {} data
 * @param {*} fileNm
 */
const openPdf = async (data, fileNm) => {
  // console.debug(fileNm + " 새창으로 열기");

  const link = document.createElement("a");
  link.href = URL.createObjectURL(
    new Blob([data], { type: "application/pdf" })
  );
  link.title = fileNm;
  link.target = fileNm;
  // link.download = fileNm;
  // link.click();
  // URL.revokeObjectURL(link.href);

  // //case 01
  // const newWindow = window.open(link);
  // newWindow.document.title = fileNm;
  // newWindow.onload = function () {
  //   this.document.title = fileNm;
  // };

  // //case 02
  // var newWindow = window.open();
  // newWindow.document.write(
  //   ' <iframe src="' +
  //     link +
  //     '" frameborder="0" width="100%" height="100%"></iframe>'
  // );
  // newWindow.document.title = fileNm;

  var newWindow = window.open();
  newWindow.document.write(
    ' <object data="' +
      link +
      '" type="application/pdf" width="100%" height="100%"> <p>대체 콘텐츠입니다. 브라우저가 PDF 삽입을 지원하지 않거나 PDF 파일을 찾을 수 없습니다. <a href="' +
      link +
      '">직접 다운로드</a></p></object>'
  );
  newWindow.document.title = fileNm;
};

export {
  checkPdf,
  downloadByTemlatePath,
  downloadByFullPath,
  _getTemlatePath,
  _downloadTemplate,
};
