/**
 * 소식 페이지
 * @param {*} state
 * @param {*} data
 */
export function setPage(state, data) {
  state.recruitPage = data || { list: {}, navInfo: {} };
}

/**
 * 소식 상세
 * @param {} state
 * @param {*} data
 */
export function setDetail(state, data) {
  state.recruitDetail = data || {};
}
