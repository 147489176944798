import { axiosHomepage } from "@/api";

export const name = "recruit";

/**
 * 채용 목록
 * @param {*} pageNum
 */
const _getPage = async (pageNum) => {
  const res = await axiosHomepage.get("/v1/rcrt/usr/list?pageNum=" + pageNum);

  if (res.status == 200) {
    return res.data.data;
  }
};

/**
 * 채용 상세
 * @param {*} ntcNo
 */
const _getDetail = async (postNo) => {
  const res = await axiosHomepage.get("/v1/rcrt/usr/" + postNo);
  if (res.status == 200) {
    return res.data.data;
  }
};

export { _getPage, _getDetail };
