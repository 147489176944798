<script setup>
import { useRoute } from "vue-router";

const route = useRoute();

//console.log("news-blog", route.meta);
const newsMeta = route.meta;
</script>

<template>
  <div class="title-area">
    <h2 class="h2">소식</h2>
  </div>
  <div class="tab">
    <ul class="tab-wrap">
      <slot v-for="(row, idx) in newsMeta.subMenu" :key="idx">
        <li v-if="row.onoff">
          <router-link :to="row.link" class="nav-link"
            ><span>{{ row.title }}</span></router-link
          >
        </li>
      </slot>
    </ul>
  </div>
</template>

<style scoped>
h1,
h4 {
  background-color: aqua;
}
</style>
