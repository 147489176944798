import LayoutMain from "@/layout/LayoutMain.vue";
import OllacarePage from "@/views/ollacare/OllacarePage.vue"; // 추가

const ollacare = {
  path: "ollacare",
  component: LayoutMain,
  children: [
    {
      path: "",
      name: "OllacarePage",
      component: OllacarePage,
    },
  ],
};

export default ollacare;