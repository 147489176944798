import axios from "axios";
import router from "@/router";
import store from "@/store";

const DEBUG = process.env.NODE_ENV === "development";
const QA = process.env.NODE_ENV === "qa";

const axiosHomepage = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL_HMPG,
  timeout: 2000,
});

// https://github.com/axios/axios#global-axios-defaults
// axiosHomepage.defaults.headers["Content-Type"] =
//   "application/json;charset=utf-8";
axiosHomepage.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axiosHomepage.defaults.headers.common["Access-Control-Allow-Headers"] = "*";

/**
 * 요청 인터셉터
 */
axiosHomepage.interceptors.request.use(
  (config) => {
    if (DEBUG || QA) {
      console.debug("interceptors request config ", config);
    }

    //+헤더설정
    //config.headers.authorization = "my secret token";
    //config.headers.XXXX = "..."
    return config;
  },
  (error) => {
    console.error("[요청오류]", error);
    //    return Promise.reject(error);

    store.commit("showModalAlert", {
      message: "[요청오류] 서버에 연결할 수 없습니다.",
    });
    return new Promise(() => {});
  }
);

/**
 * 응답 인터셉터
 */
axiosHomepage.interceptors.response.use(
  (response) => {
    //console.debug("response.status", response.status);

    return response;
  },
  (error) => {
    console.debug(error);
    if (error.response && error.response.status) {
      console.error("server-api response error", error.response);

      switch (error.response.status) {
        case 400:
          store.commit("showModalAlert", {
            message: "[응답오류]<br>잘못된 요청입니다.",
          });

          return new Promise(() => {});
        case 404:
          router.push("/error/404").catch(() => {});
          return new Promise(() => {});
        case 500:
          router.push("/error/500").catch(() => {});
          return new Promise(() => {});
        default:
          return Promise.reject(error);
      }
    }

    if (!error.response) {
      console.error("server-api response error", error);
      // store.commit("showModalAlert", {
      //   message: "[응답오류]<br>서버에 연결할 수 없습니다.",
      // });
      return new Promise(() => {});
    }

    router.push("/error/404").catch(() => {});
    return Promise.reject(error);
  }
);

export { axiosHomepage };
