<template>
  <div id="container" data-ui-page="terms">
    <div id="content" class="content">
      <div class="content-wrap">
        <div class="section">
          <div class="comp">
            <div class="terms-title">
              <h2 class="h2">개인정보처리방침</h2>
              <div class="terms-date">
                <select @change="changeComponent($event)">
                  <option
                    v-for="(item, index) in selectList"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.date }}
                  </option>
                </select>
              </div>
            </div>
            <component :is="currentComponent" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UI } from "@/resources/js/ui.common";
import PersonV15 from "@/components/terms/Person_v15.vue";
import PersonV14 from "@/components/terms/Person_v14.vue";
import PersonV13 from "@/components/terms/Person_v13.vue";
import PersonV12 from "@/components/terms/Person_v12.vue";
import PersonV11 from "@/components/terms/Person_v11.vue";
import PersonV10 from "@/components/terms/Person_v10.vue";

export default {
  name: "PersonPage",
  components: {
    PersonV15,
    PersonV14,
    PersonV13,
    PersonV12,
    PersonV11,
    PersonV10,
  },
  data() {
    return {
      selectList: [
        { date: "2023-04-17", value: "V15" },
        { date: "2022-12-27", value: "V14" },
        { date: "2022-10-21", value: "V13" },
        { date: "2022-10-12", value: "V12" },
        { date: "2022-04-04", value: "V11" },
        { date: "2022-02-05", value: "V10" },
      ],
      currentComponent: PersonV15.name,
    };
  },
  methods: {
    changeComponent(event) {
      // console.log(event.target.value)
      this.currentComponent = "Person" + event.target.value;
    },
  },
  mounted() {
    UI.init(this);
    this.currentComponent = "PersonV15";
  },
};
</script>
