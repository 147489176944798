/**
 * 소식 페이지
 * @param {*} state
 * @param {*} data
 */
export function setPage(state, { ntcFg, list, pageableInfo }) {
  recalcuatePageableInfo(pageableInfo);
  state.curNtcFg = ntcFg;
  state.curPageNum = pageableInfo.number || 0;
  state.list[ntcFg] = { list, pageableInfo } || { list: {}, pageableInfo: {} };
}

/**
 * 페이징 재계산
 * @param {*} pageableInfo
 */
function recalcuatePageableInfo(info) {
  info.naviPageUnit = 5;
  info.curPageNum = info.number + 1;
  const curNavigateNum =
    info.curPageNum % info.naviPageUnit == 0
      ? Math.floor(info.curPageNum / info.naviPageUnit)
      : Math.floor(info.curPageNum / info.naviPageUnit) + 1;
  console.debug("curNavigateNum = " + curNavigateNum);

  // const lastNavigateNum = info.totalPages;
  // console.debug("lastNavigateNum = " + info.totalPages);

  info.curNavigateNum = curNavigateNum;

  info.hasPreNavi = curNavigateNum == 1 ? false : true;
  info.curNaviFirstPage = info.pageSize * (curNavigateNum - 1) + 1;
  info.hasNextNavi =
    info.pageSize * (curNavigateNum * info.naviPageUnit) < info.totalElements
      ? true
      : false;

  info.curNaviLastPage = info.hasNextNavi
    ? info.curNaviFirstPage + info.naviPageUnit - 1
    : info.totalPages;

  info.navigatepageNums = [];
  for (var i = info.curNaviFirstPage; i <= info.curNaviLastPage; i++) {
    info.navigatepageNums.push(i - 1);
  }

  console.debug(info);
}
/**
 * 페이징 재계산
 * @param {} navInfo

function recalcuateNavInfo(navInfo) {
  const curNavigateNum =
    navInfo.pageNum % navInfo.navigatePages == 0
      ? Math.floor(navInfo.pageNum / navInfo.navigatePages)
      : Math.floor(navInfo.pageNum / navInfo.navigatePages) + 1;

  const lastNavigateNum =
    navInfo.total % navInfo.pageSize == 0
      ? Math.floor(navInfo.total / navInfo.pageSize)
      : Math.floor(navInfo.total / navInfo.pageSize) + 1;

  navInfo.curNavigateNum = curNavigateNum;

  navInfo.hasPreviousPage = curNavigateNum == 1 ? false : true;
  navInfo.navigateFirstPage = navInfo.navigatePages * (curNavigateNum - 1) + 1;

  navInfo.hasNextPage =
    navInfo.pageSize * (curNavigateNum * navInfo.navigatePages) < navInfo.total
      ? true
      : false;

  navInfo.navigateLastPage = navInfo.hasNextPage
    ? curNavigateNum * navInfo.navigatePages
    : lastNavigateNum;

  //console.debug(navInfo);
  navInfo.navigatepageNums = [];
  for (var i = navInfo.navigateFirstPage; i <= navInfo.navigateLastPage; i++) {
    navInfo.navigatepageNums.push(i);
  }
}
 */
/**
 * 소식 상세
 * @param {} state
 * @param {*} data
 */
export function setDetail(state, { ntcFg, data }) {
  if (state.curNtcFg != ntcFg) {
    state.curNtcFg = ntcFg;
    state.curPageNum = -1;
  }
  state.detail[ntcFg] = data;
}
