import LayoutNews from "@/layout/LayoutNews.vue";
import LayoutNewsList from "@/layout/LayoutNewsList.vue";

const news = {
  path: "news",
  name: "NewsPage",
  //component: LayoutNews,
  meta: {
    title: "국민과 건강사이 KB헬스케어",
    unauthorized: true,
    menuInfo: {
      desc: "소식입니다.",
    },
    subMenu: [
      {
        link: { name: "PressPage", query: { pageNum: 0 } },
        name: "PressPage",
        to: "/list/press",
        title: "언론보도",
        onoff: true,
      },
      {
        link: { name: "NoticePage", query: { pageNum: 0 } },
        name: "NoticePage",
        to: "/list/notice/0",
        title: "공지사항",
        onoff: true,
      },
      {
        link: { name: "BlogPage", query: { pageNum: 0 } },
        name: "BlogPage",
        to: "/list/blog/0",
        title: "블로그",
        onoff: true,
      },
      {
        link: { name: "KbfgPage", query: { pageNum: 0 } },
        name: "KbfgPage",
        to: "/list/kbfg/0",
        title: "그룹사 소식",
        onoff: true,
      },
    ],
  },
  children: [
    {
      path: "list",
      component: LayoutNewsList,
      children: [
        {
          path: "press",
          alias: "/list/press",
          name: "PressPage",
          props: true,
          meta: {
            ntcFg: "PRSS",
            toList: "PressPage",
            toDetail: "PressDetail",
          },
          component: () => import("@/views/news/PressList.vue"),
        },
        {
          path: "notice",
          alias: "/list/notice",
          name: "NoticePage",
          props: true,
          meta: {
            ntcFg: "NTC",
            toList: "NoticePage",
            toDetail: "NoticeDetail",
          },
          component: () => import("@/views/news/NoticeList.vue"),
        },
        {
          path: "blog",
          alias: "/list/blog",
          name: "BlogPage",
          props: true,
          meta: {
            ntcFg: "BLOG",
            toList: "BlogPage",
            toDetail: "BlogDetail",
          },
          component: () => import("@/views/news/BlogList.vue"),
        },
        {
          path: "kbfg",
          alias: "/list/kbfg",
          name: "KbfgPage",
          props: true,
          meta: {
            ntcFg: "KBFG",
            toList: "KbfgPage",
            toDetail: "KbfgDetail",
          },
          component: () => import("@/views/news/KbfgList.vue"),
        },
      ],
    },
    {
      path: "detail",
      component: LayoutNews,
      children: [
        {
          path: "press/:id(\\d+)",
          alias: "/detail/press/:id(\\d+)",
          name: "PressDetail",
          props: true,
          meta: {
            ntcFg: "PRSS",
            toList: "PressPage",
            toDetail: "PressDetail",
          },
          component: () => import("@/views/news/PressDetail.vue"),
        },
        {
          path: "notice/:id(\\d+)",
          alias: "/detail/notice/:id(\\d+)",
          name: "NoticeDetail",
          props: true,
          meta: {
            ntcFg: "NTC",
            toList: "NoticePage",
            toDetail: "NoticeDetail",
          },
          component: () => import("@/views/news/NoticeDetail.vue"),
        },
        {
          path: "blog/:id(\\d+)",
          alias: "/detail/blog/:id(\\d+)",
          name: "BlogDetail",
          props: true,
          meta: {
            ntcFg: "BLOG",
            toList: "BlogPage",
            toDetail: "BlogDetail",
          },
          component: () => import("@/views/news/BlogDetail.vue"),
        },
        {
          path: "kbfg/:id(\\d+)",
          alias: "/detail/kbfg/:id(\\d+)",
          name: "KbfgDetail",
          props: true,
          meta: {
            ntcFg: "KBFG",
            toList: "KbfgPage",
            toDetail: "KbfgDetail",
          },
          component: () => import("@/views/news/KbfgDetail.vue"),
        },
      ],
    },
  ],
};

export default news;
